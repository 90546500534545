import FacebookYellow from 'assets/img/landing/facebook-yellow.svg'
import InstagramYellow from 'assets/img/landing/instagram-yellow.svg'
import LinkedInYellow from 'assets/img/landing/linkedin-yellow.svg'
import SendCand from 'assets/img/landing/sendCand.svg'
import { Button } from 'antd'
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'

export const FooterFront = () => {
    const history = useHistory();
    const currentYear = new Date().getFullYear();

    const onApply = () => {
        history.push(`/application`)
    }

    return(
        <div className='landing-footer mt-6'>
        <div className='container'>
            <div className='yellow-card d-flex flex-column justify-content-center align-items-center '>
                <div className='indicator'>
                    <img src={SendCand} alt="SendCand" />
                </div>
                <h2 className='mb-5'>
                    SOUMETTRE VOTRE CANDIDATURE
                </h2>
                {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet <br className='d-none d-md-block' /> non ornare purus nunc, eget id ut elementum pellentesque.
                </p> */}
                <Button type='primary' style={{
                    maxWidth: 200,
                    alignSelf: "center"
                }} className='custom-button green-button' onClick={() => onApply()} >Candidature</Button>
            </div>
            <div className='faq'>
                <div className='d-none d-md-block'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='text footer-copyRight'>
                            © {currentYear} Mliha Tous droits réservés
                        </div>
                        <div className='social-network'>
                        <a href="https://www.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={FacebookYellow} alt="Facebook" className='mr-2 pointer' /></a>
                        <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={InstagramYellow} alt="Instagram" className='ml-2 mr-2 pointer' /></a>
                        <a href="https://www.linkedin.com/company/mliha/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={LinkedInYellow} alt="Linkedin" className='ml-2 pointer' /></a>
                        </div>
                        <div className='text link-footer-bottom footer-links-contact'>
                            <Link className='link-footer' to="/contact" title='Contact'>Contactez-nous</Link>
                            {/* -
                            <Link className='link-footer' to="/" title='Conditions générales'>Conditions générales</Link> */}
                        </div>
                    </div>

                </div>

                <div className='d-md-none d-flex text-center  d-flex flex-column align-items-center'>
                    <div className='mb-4 mb-md-0'>
                    <a href="https://www.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={FacebookYellow} alt="FacebookYellow" className='mr-2 pointer' /></a>
                    <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={InstagramYellow} alt="InstagramYellow" className='ml-2 mr-2 pointer' /></a>
                    <a href="https://www.linkedin.com/company/mliha/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={LinkedInYellow} alt="Linkedin" className='ml-2 pointer' /></a>
                    </div>


                    <div className='text mb-4 mb-md-0 link-footer-bottom'>
                        <Link className='link-footer' to="/contact" title='Contact'>Contactez-nous</Link>
                        {/* -
                        <Link className='link-footer' to="/" title='Conditions générales'>Conditions générales</Link> */}
                    </div>
                    <div className='text link-footer-bottom'>
                        © {currentYear} Mliha Tous droits réservés
                    </div>
                </div>
            </div>
        </div>

    </div>
    )

}