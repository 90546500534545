export const SUPER_ADMIN = 'Super Admin';
export const CHEF_ZONE = "Chef de zone";
export const DIRECTEUR_DEV_RESEAU = "Directeur dev. réseau";
export const RESPONSABLE_RESEAU = "Responsable dev. réseau";
export const DIRECTEUR_COMMERCIAL = "Directeur commercial";
export const ARCHITECTE = "Architecte";
export const DIRECTEUR_ACHAT = "Direction achat";
export const RESPONSABLE_JURIDIQUE = "Responsable juridique";
export const RESPONSABLE_QUALITE = "Responsable management qualité";
export const CANDIDAT = "Candidat";
export const RESPONSABLE_RESEAU_BO = "Responsable dev réseau BO";
export const CHEF_PRODUIT_MARKETING = "Chef De Produit Marketing";
export const DIRECTEUR_GENERAL = "Directeur Général";
export const RESPONSABLE_TRADE = "Responsable Trade";
export const DIRECTEUR_MARKETING = "Directeur marketing";
export const DIRECTEUR_CONTROLE_GESTION = "Directeur Contrôle Gestion";
