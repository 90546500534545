import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import navigationConfig from "configs/NavigationConfig";
import { useSelector } from "react-redux";
import { SUPER_ADMIN, CHEF_ZONE, RESPONSABLE_JURIDIQUE, RESPONSABLE_QUALITE, RESPONSABLE_RESEAU, RESPONSABLE_RESEAU_BO, DIRECTEUR_GENERAL, DIRECTEUR_MARKETING, DIRECTEUR_DEV_RESEAU, DIRECTEUR_COMMERCIAL, CANDIDAT,DIRECTEUR_CONTROLE_GESTION } from 'constants/RolesConstant'



export const AppViews = () => {
  const { profile } = useSelector(state => state.auth)
  const role = profile?.role.name; //Get Role after from redux
  const redirectUSer = () => {

  }
  return (
    <Suspense fallback={null}>
      <Switch>
        {navigationConfig.map((page) => page.roles.includes(role) ? (<Route exact key={page.key} path={`${page.path}`} component={lazy(() => import(`${page.componentPath}`))} />) : null)}
        {role && ((role === SUPER_ADMIN || role === DIRECTEUR_DEV_RESEAU || role === DIRECTEUR_GENERAL || role === DIRECTEUR_MARKETING || role === DIRECTEUR_CONTROLE_GESTION) ? <Redirect exact from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} /> : <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/applications/gerances`} />)}

        <Route path="*" component={lazy(() => import(`../landing-views/page-not-found`))} ></Route>
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);