import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_POS
} from '../constants/POS';
import {
    getPosSuccess
} from "../actions/POS";


import services from 'services/PrivateServices';




export function* GetPos() {

    yield takeEvery(GET_POS, function* () {

        try {

            const data = yield call(services.getPosData);

            const fomattedData = data.data

            yield put(getPosSuccess(fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetPos),

    ]);
}
