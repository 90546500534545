import axios from 'axios'

//Init Default Base URL
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const publicServices = {}

// Add Contact 
publicServices.addContact = (data) => axios.post('items/Contact', data)

//Get FAQ Front
publicServices.getFaqs = () => axios.get('items/FAQ')

publicServices.getApplications = (phone,email) => axios.get(`https://bridge.machrou3i.mliha.tn/webhook/check-application-exists?phone_number=${phone}&email=${email}`)

export default publicServices