import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_APPLICATION_HISTORY,
    GET_APPLICATION_HISTORY_CALENDAR,
    GET_LISTED_APPLICATION_HISTORY
} from '../constants/ApplicationHistory';
import {
    getApplicationHistorySuccess,
    getApplicationHistoryCalendarSuccess,
    getListedApplicationHistorySuccess
} from "../actions/ApplicationHistory";


import services from 'services/PrivateServices';




export function* GetApplicationHistory() {

    yield takeEvery(GET_APPLICATION_HISTORY, function* ({ filter }) {

        try {
            const data = yield call(services.getHistoryApplication, filter);

            const fomattedData = data

            yield put(getApplicationHistorySuccess(fomattedData))

        } catch (err) {

        }
    });
}
export function* GetApplicationHistoryCalendar() {

    yield takeEvery(GET_APPLICATION_HISTORY_CALENDAR, function* ({ filter }) {

        try {
            const data = yield call(services.getHistoryApplicationCalendar, filter);

            const fomattedData = data

            yield put(getApplicationHistoryCalendarSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* GetListedHistoryApplication() {

    yield takeEvery(GET_LISTED_APPLICATION_HISTORY, function* ({ filter }) {
        try {
            const data = yield call(services.getListedHistoryApplication, filter);

            const fomattedData = data

            yield put(getListedApplicationHistorySuccess(fomattedData))

        } catch (err) {

        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(GetApplicationHistory),
        fork(GetApplicationHistoryCalendar),
        fork(GetListedHistoryApplication),
    ]);
}
