import { GET_APPLICATION_HISTORY, GET_APPLICATION_HISTORY_SUCCESS,GET_APPLICATION_HISTORY_CALENDAR,GET_APPLICATION_HISTORY_CALENDAR_SUCCESS,GET_LISTED_APPLICATION_HISTORY,GET_LISTED_APPLICATION_HISTORY_SUCCESS } from "redux/constants/ApplicationHistory";

export const getApplicationHistory = (filter) => {
    return {
        type: GET_APPLICATION_HISTORY,
        filter
    };
};

export const getApplicationHistorySuccess = (application) => {
    return {
        type: GET_APPLICATION_HISTORY_SUCCESS,
        payload: application
    }
};

export const getApplicationHistoryCalendar = (filter) => {
    return {
        type: GET_APPLICATION_HISTORY_CALENDAR,
        filter
    };
};

export const getApplicationHistoryCalendarSuccess = (application) => {
    return {
        type: GET_APPLICATION_HISTORY_CALENDAR_SUCCESS,
        payload: application
    }
};
export const getListedApplicationHistory = (filter) => {
    return {
        type: GET_LISTED_APPLICATION_HISTORY,
        filter
    };
};

export const getListedApplicationHistorySuccess = (application) => {
    let formattedData = {
        meta: application?.meta, 
        data:[]
    }
    
    application.data.map((app)=>{
        formattedData.data.push({
                id : app?.application?.id,
                first_name : app?.application?.first_name,
                last_name : app?.application?.last_name,
                phone_number : app?.application?.phone_number,
                email : app?.application?.email,
                city : app?.application?.city,
                isFranchise : app?.application?.isFranchise,
                governorate_id : app?.application?.governorate_id,
                application_history: {
                    ...app?.application?.application_history,
                    current_application_status: app?.current_application_status
                },
                date_created : app?.application?.date_created,
            
        })
    })
    
    return {
        type: GET_LISTED_APPLICATION_HISTORY_SUCCESS,
        payload: formattedData
    }
};