import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, RESET_PASSWORD_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import LandingHome from "./landing-views/home";
import Application from "./landing-views/application";
import PageNotFound from "./landing-views/page-not-found"
import Faq from "./landing-views/faq";
import Contact from './landing-views/contact'
import { getMe } from "redux/actions/Auth";
import { getGovs,getGovsFront,getFieldsApp,getCategoryContact } from "redux/actions/Governoments";
function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
export const Views = (props) => {
  const { locale, location, direction, profile, token, govers, fields_app,category_contact } = props;
  const currentAppLocale = AppLocale[locale];
  const dispatch = useDispatch()
  useEffect(() => {
    if (token) {
      !profile && dispatch(getMe())
      !govers && dispatch(getGovs())
    }else{
      !govers && dispatch(getGovsFront())
      !fields_app && dispatch(getFieldsApp())
    }
    !category_contact && dispatch(getCategoryContact())
  }, [token, profile])


  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <LandingHome />
          </Route>
          <Route exact path="/application">
            <Application />
          </Route>
          <Route exact path="/faq">
            <Faq />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} location={location} />
          </Route>
          <Route path={RESET_PASSWORD_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <Route path="*" >
            <PageNotFound showButton={true}/>
          </Route>  
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth, govs }) => {
  const { locale, direction } = theme;
  const { token, profile } = auth;
  const govers = govs.govs;
  const fields_app = govs.fields_app
  const category_contact = govs.category_contact
  return { locale, token, direction, profile, govers,fields_app,category_contact}
};

export default withRouter(connect(mapStateToProps)(Views));