import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Users from './Users'
import Roles from './Roles'
import Governoments from './Governoments'
import Applications from './Applications'
import Calendar from './Calendar'
import ApplicationHistory from './ApplicationHistory'
import ApplicationStatus from './ApplicationStatus'
import Pos from './POS'
import Company from './CompanyType'
import FormFields from './FormFields'
import Dashboard from './Dashboard'
import Reclamations from './Reclamations'

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Users(),
    Roles(),
    Governoments(),
    Applications(),
    Calendar(),
    ApplicationHistory(),
    ApplicationStatus(),
    Pos(),
    Reclamations(),
    Company(),
    FormFields(),
    Dashboard()
  ]);
}
