import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_APPLICATIONS,
  GET_APPLICATIONS_BY_ID,
  GET_APPLICATIONS_FIELDS,
  GET_APPLICATION_STEPS,
  GET_LISTED_APPLICATIONS,
  GET_CALENDAR_APPLICATIONS
} from "../constants/Applications";
import {
  getApplicationByIdSuccess,
  getApplicationsFiedlsSuccess,
  getApplicationsSuccess,
  getApplicationStepsSuccess,
  getListedApplicationsSuccess,
  getCalendarApplicationsSuccess
} from "../actions/Applications";
import services from "services/PrivateServices";

export function* GetApplications() {
  yield takeEvery(GET_APPLICATIONS, function* ({ page, filter }) {
    try {
      const data = yield call(services.getApplications, page, filter);
      const fomattedData = data;
      yield put(getApplicationsSuccess(fomattedData));
    } catch (err) {}
  });
}
export function* GetGeranceApplications() {
  yield takeEvery(GET_LISTED_APPLICATIONS, function* ({ page, filter }) {
    try {
      const data = yield call(services.getListedApplications, page, filter);
      const fomattedData = data;
      yield put(getListedApplicationsSuccess(fomattedData));
    } catch (err) {}
  });
}
export function* GetCalendarApplications() {
  yield takeEvery(GET_CALENDAR_APPLICATIONS, function* ({ page, filter }) {
    try {
      const data = yield call(services.getCalendarApplications, page, filter);
      const fomattedData = data;
      yield put(getCalendarApplicationsSuccess(fomattedData));
    } catch (err) {}
  });
}

export function* GetApplicationById() {
  yield takeEvery(GET_APPLICATIONS_BY_ID, function* ({ id }) {
    try {
      const data = yield call(services.getApplicationById, id);
      const fomattedData = data.data;
      yield put(getApplicationByIdSuccess(fomattedData));
    } catch (err) {}
  });
}
export function* GetApplicationFields() {
  yield takeEvery(GET_APPLICATIONS_FIELDS, function* () {
    try {
      const data = yield call(services.getApplicationFileds);
      const fomattedData = data.data;
      yield put(getApplicationsFiedlsSuccess(fomattedData));
    } catch (err) {}
  });
}
export function* GetApplicationSteps() {
  yield takeEvery(GET_APPLICATION_STEPS, function* ({}) {
    try {
      const data = yield call(services.getApplicationSteps);
      const fomattedData = data.data;
      yield put(getApplicationStepsSuccess(fomattedData));
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(GetApplications),
    fork(GetGeranceApplications),
    fork(GetApplicationById),
    fork(GetApplicationFields),
    fork(GetApplicationSteps),
    fork(GetCalendarApplications),
  ]);
}
