import { useEffect,useState } from "react";
import { HeaderFront } from "components/layout-components/HeaderFront"
import { Row, Card, Collapse } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { FooterFront } from "components/layout-components/FooterFront";
import publicServices from "services/PublicService";
import { Skeleton } from 'antd';

const { Panel } = Collapse;

const Faq = () => {
    const [faqList,setFaqList] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect( () => {
        publicServices.getFaqs().then(res => {
            setFaqList(res.data.data);
            setLoading(false);
        });
    },[])
            

    return(
        <>
        <HeaderFront />
        <div className="container faq-page">
        <Row gutter={16}>
            <h1 className="title-center">FAQ</h1>
            <p className="subtitle">Vous pouvez également nous contacter si vous avez besoin d'aide.</p>
            {!loading ? <Card className="mt-4 card-faq">
				<Collapse 
					accordion 
					defaultActiveKey={'faq-1-1'} 
					bordered={false}
					expandIcon={({ isActive }) => <RightOutlined className="text-primary" type="right" rotate={isActive ? 90 : 0} />}
				>
                    {faqList.map( elm => (
                        <Panel header={elm.question} key={elm.id}>
                            <p>{elm.response}</p>
                        </Panel>
                    ))}
				</Collapse>
            </Card> : <Skeleton/>}
        </Row>
        </div>
        <FooterFront />
        </>
    );

}

export default Faq