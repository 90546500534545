import service from "auth/FetchInterceptor"

import { SUPER_ADMIN, DIRECTEUR_DEV_RESEAU, CANDIDAT, RESPONSABLE_RESEAU } from 'constants/RolesConstant'



const services = {}

const folder_avatars = process.env.REACT_APP_ID_FOLDER_AVATAR

services.updateUser = (id, data) => service.patch(`users/${id}`, data)
services.getUserInformations = () => service.get('users/me', {

    params: {
        fields: "*, role.*, governorates.*"
    },
})

services.addUserData = (payload) => service.post(`/users`, payload)
//Get List Users
services.getUserData = (page = 1, filter = undefined) => service.get('/users', {
    params: {
        fields: "*,role.*,governorates.*, governorates.Governorate_id.*",
        page,
        limit: 10,
        filter: {
            ...filter,
            "role": {
                "name": { "_nin": [SUPER_ADMIN, DIRECTEUR_DEV_RESEAU, CANDIDAT] }
            }
        },
        meta: "*",
    }
})
//Get List POS
services.getPosData = () => service.get('/items/POS', {
    params: {
        limit: 10000,
        fields: "*,company.*"
    }
})


//Get List Company
services.getCompanyData = () => service.get('/items/company_type', {
    params: {
        limit: 10000,
        sort: "-title"
    }
})
//Get applications List
services.getApplications = (page = 1, filter = undefined) => service.get('/items/application', {
    params: {
        fields: "*,governorate_id.*, application_history.*,application_history.application.*,application_history.application.governorate_id.*, application_history.current_application_status.*,application_history.current_application_status.step_id.*,application_history.calendar.*,application_history.action.*,application_history.user_created.*,application_history.chef_zone.*,application_history.current_application_status.visible_for_roles.directus_roles_id",
        // page,
        // limit: 10,
        limit: 10000,
        filter: {
            ...filter,
        },
        meta: "*",
        sort: "-id"
    }
})
services.getApplicationById = (id) => service.get(`/items/application/${id}`, {
    params: {
        fields: `*,application_history.docs.*,application_history.application.*, 
        application_history.application.governorate_id.*,application_history.action.*,
        application_history.governerate.*,application_history.docs.file.*,
        application_history.docs.type.*,governorate_id.*,governorate_id.managers.*,governorate_id.managers.directus_users_id.*,governorate_id.managers.directus_users_id.role.*, application_history.*, application_history.user_created.*, application_history.user_created.role.*,
         application_history.action.*, application_history.survey.*,application_history.visit.*,application_history.visit.gouvernorat.*, application_history.visit.photos.*, application_history.action.*, application_history.action.authorized_roles.*,application_history.calendar.*, application_history.current_application_status.*,application_history.current_application_status.step_id.*, application_history.current_application_status.actions.*,application_history.current_application_status.actions.Action_id.*, application_history.current_application_status.actions.Action_id.authorized_roles.*,application_history.current_application_status.actions.Action_id.docs_needed.*,
         application_history.action.require.*,
         application_history.current_application_status.actions.*,
         application_history.current_application_status.actions.Action_id.docs_needed.Docs_id.*,
         application_history.current_application_status.actions.Action_id.status_FR.*,
         application_history.current_application_status.actions.Action_id.status_GL.*,
         application_history.current_application_status.actions.Action_id.require.*,
         application_history.current_application_status.actions.Action_id.require.Action_id.*,
         application_history.current_application_status.actions.Action_id.require.ApplicationStatus_id.id,
         application_history.current_application_status.authorized_roles.directus_roles_id.name,
         application_history.current_application_status.step_id.sub_step.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.Action_id.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.Action_id.require.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.Action_id.require.Action_id.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.Action_id.require.Action_id.require.*,
         application_history.current_application_status.step_id.sub_step.initial_actions.Action_id.authorized_roles.*,

         application_history.current_application_status.initialization_action.*,
         application_history.current_application_status.initialization_action.Action_id.authorized_roles.*,
         application_history.current_application_status.initialization_action.Action_id.*,
         application_history.current_application_status.initialization_action.Action_id.docs_needed.Docs_id.*,
         application_history.current_application_status.initialization_action.Action_id.status_FR.*,
         application_history.current_application_status.initialization_action.Action_id.status_GL.*,
         application_history.current_application_status.initialization_action.Action_id.require.*,
         application_history.current_application_status.initialization_action.Action_id.require.Action_id.*,
         application_history.current_application_status.initialization_action.Action_id.require.ApplicationStatus_id.id,
         application_history.chef_zone.*`,
    }
})

services.getListedApplications = (page = 1, filter = undefined) =>
  service.get("/items/application", {
    params: {
      fields:
        "id,status,first_name,last_name,phone_number,email,governorate_id.name,city,date_created,isFranchise,application_history.current_application_status.name,application_history.current_application_status.visible_for_roles.*,application_history.current_application_status.color,application_history.date_created",
      page,
      filter: {
        ...filter,
      },
      limit: -1,
      meta: "*",
      sort: "-id",
    },
  });
services.getDashboardApplications = () =>
  service.get("/items/application", {
    params: {
      fields:
        "isFranchise,governorate_id.name,governorate_id.id, application_history.current_application_status.statistic_dashboard, application_history.date_created, application_history.current_application_status.statistic_dashboard",
      limit: -1,
      meta: "*",
      sort: "-id",
    },
  });
services.getCalendarApplications = (filter = undefined) => service.get('/items/application', {
    params: {
        fields: "application_history.user_created.id, application_history.user_created.first_name, application_history.user_created.last_name, application_history.date_created, application_history.application.id, application_history.application.first_name, application_history.application.last_name, application_history.application.phone_number, application_history.application.email, application_history.application.governorate_id.name, application_history.calendar.id, application_history.calendar.start_date, application_history.calendar.end_date, application_history.action.color, application_history.chef_zone.first_name, application_history.chef_zone.last_name, application_history.current_application_status.name, application_history.current_application_status.step_id.name",
        limit: -1,
        filter: {
            ...filter,
        },
        meta: "*",
        sort: "-id"
    }
})

services.getApplicationFileds = () => service.get(`/fields/application`)
services.getFormFileds = (formType) => service.get(`/fields/${formType}`)
services.getApplicationSteps = (filterRoule) => service.get('/items/ApplicationStep', {
    params: {
        filter: {
            ...filterRoule
        }
    }
})

/* 
* Get Roles
*/
services.getRoleData = () => service.get('/roles', {

    params: {
        limit: 10000,
        fields: "*,role.*",
        filter: {
            "name": { "_nin": [SUPER_ADMIN, DIRECTEUR_DEV_RESEAU, CANDIDAT] }
        },
        sort: "name"
    }
})
/*
* Get User By ID
*/
services.getUserById = (id) => service.get(`/users/${id}`, {
    params: {
        fields: "*,role.*,governorates.*",
        filter: {
            "_and": [
                {
                    "role": {
                        "name": { "_nin": [SUPER_ADMIN] }
                    }
                }
            ]
        }
    }
})
/*
* Upload Image 
*/
services.uploadImage = async (image) => {
    try {
        const data = new FormData()
        data.append(image.name, image)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data;",
            }
        }
        data.append("folder", folder_avatars)
        const newLogo = await service.post(`files`, data, config)
        return newLogo.data.id
    } catch (error) {
        throw error
    }
}

/* 
* Get gouvernorats
*/
services.getGovsData = () => service.get('/items/Governorate',{
    params: {
        limit: 100,
        sort: "name"
    }
})


/* 
* Get gouvernorats
*/
services.getCalendarData = () => service.get('/items/Calendar')

/*
* Check Existe User By Email 
*/
services.getUserDataByEmail = (email) => service.get('/users', {
    params: {
        fields: "id",
        limit: 1,
        filter: {
            "email": { "_eq": email }
        },
        meta: "*",
    }
})
/*
* Get Survey Field
*/
services.getSurveyFields = () => service.get('/fields/Survey')
/*
* Get Application Field
*/
services.getApplicationFields = () => service.get('/fields/application')
services.getSurveyField = () => service.get('/fields/Survey')
/*
* Get Application Field
*/
services.getApplicationField = () => service.get('/fields/application')
/*
* Get Application Status
*/
services.getApplicationStatusData = () => service.get('/items/ApplicationStatus', {
  params: {
    fields:
      "name",
    limit: -1,
    meta: "*",
    sort: "-id",
  },
})
/*
* Get applications Item
*/
services.getItemApplication = (id) => service.get(`/items/application/${id}`)
/*
* Update Application by ID
*/
services.updateApplication = (id, data) => service.patch(`/items/application/${id}`, data)
/*
* Get Application History
*/
services.getHistoryApplication = (filter) => service.get(`/items/ApplicationHistory`, {
    params: {
        limit: 10000,
        filter,
        fields: "*, current_application_status.*,chef_zone.*,user_created.*,current_application_status.step_id.*,calendar.*,action.*,application.*,application.governorate_id.*",
        meta: "*"
    }
})
services.getHistoryApplicationCalendar = (filter = undefined) => service.get(`/items/ApplicationHistory`, {
  params: {
      limit: -1,
      filter: {
        ...filter,
      },
      fields: "user_created.id, user_created.first_name, user_created.last_name, date_created, application.id, application.first_name, application.last_name, application.phone_number, application.email, application.governorate_id.name, calendar.id, calendar.start_date, calendar.end_date, action.color, chef_zone.first_name, chef_zone.last_name, current_application_status.name, current_application_status.step_id.name",
      meta: "*"
  }
})
services.getListedHistoryApplication = (page = 1, filter = undefined) =>
  service.get("/items/ApplicationHistory", {
    params: {
      fields:
        "application.id,application.first_name,application.last_name,application.phone_number,application.email,application.governorate_id.name,application.city,application.date_created,application.isFranchise,current_application_status.name,current_application_status.color",
    page,
    filter: {
        ...filter,
    },
    limit: 10,
    meta: "*",
    sort: "-id",
    },
  });
/*
* Add Application History
*/
services.addHistoryApplication = async (data) => await service.post(`/items/ApplicationHistory`, data)
/*
* Get Survey Field
*/
services.getVisitFields = () => service.get('/fields/Visits')
/*
* Get Survey Field
*/
services.getBenefitFields = () => service.get('/fields/etude_de_rentabilit')
/*
* Upload File In Folder ID 
*/
services.uploadFile = async (file, folder = null) => {
    try {
        const data = new FormData()
        if (folder) {
            data.append("folder", folder)
        }
        data.append('file', file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data;",
            }
        }
        const file_uploaded = await service.post(`files`, data, config)
        return file_uploaded.data.id
    } catch (error) {
        throw error
    }
}
/*
* Upload File In Folder ID And Return Array Format 
*/
services.uploadFileByFieldName = async (file, field_name, folder = null) => {
    try {
        const filesArray = [];
        const data = new FormData()
        if (folder) {
            data.append("folder", folder)
        }
        data.append('file', file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data;",
            }
        }
        const file_uploaded = await service.post(`files`, data, config)
        filesArray['field'] = field_name;
        filesArray['id_file'] = file_uploaded.data.id;
        return filesArray
    } catch (error) {
        throw error
    }
}

//Get List Users RDR
services.getAllUserRdr = () => service.get('/users', {
    params: {
        fields: "*,role.*,governorates.*, governorates.Governorate_id.*",
        filter: {
            "role": {
                "name": { "_in": [RESPONSABLE_RESEAU] }
            }
        },
        meta: "*",
    }
})

//Get List Reclamations
services.getReclamationsData = (page = 1, filter = undefined) => service.get('/items/Contact', {
    params: {
        fields: "*,category_reclamation.*, sub_category_reclamation.*",
        page,
        limit: 10,
        filter: {
            ...filter,
            "category": { "_eq": "Réclamation" }
        },
        sort: "-date_created",
        meta: "*",
    }
})

export default services