import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	GET_ME,
	SIGNIN,
	SIGNOUT,
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	getMe,
	getMeSuccess,
	redirectUserAccordingToItRole,
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'
import axios from 'axios';
import services from 'services/PrivateServices';
import { message } from 'antd';
import Utils from 'utils';


const signInEmailRequest = async (email, password) => {

	try {
		const user = await axios.post(`auth/login`, { email, password })
		return user.data
	} catch (err) {
		throw err
	}
}
export function* signIn() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(signInEmailRequest, email, password);
			localStorage.setItem(AUTH_TOKEN, user.data.access_token)
			yield put(authenticated(user.data.access_token));
			const profile = yield call(services.getUserInformations);
			const fomattedData = profile.data
			yield put(redirectUserAccordingToItRole(fomattedData.role.name))
			yield put(getMeSuccess(fomattedData))
		} catch (err) {
			const message = err.response.data.errors[0].message
			yield put(showAuthMessage(message));
		}
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}
export function* GetUserInfo() {
	yield takeEvery(GET_ME, function* () {
		try {
			const user = yield call(services.getUserInformations);
			const fomattedData = user.data
			yield put(getMeSuccess(fomattedData))
		} catch (err) {
			throw err
		}
	});
}





export default function* rootSaga() {
  yield all([
		fork(signIn),
		fork(signOut),
		fork(GetUserInfo)
  ]);
}
