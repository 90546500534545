import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_RECLAMATIONS,
    UPDATE_RECLAMATIONS, 
} from '../constants/Reclamations';
import {
    getReclamationsSuccess,
    updateReclamationSuccess,
} from "../actions/Reclamations";
import services from 'services/PrivateServices';

export function* GetReclamation() {

    yield takeEvery(GET_RECLAMATIONS, function* ({ page, filter }) {

        try {
            const data = yield call(services.getReclamationsData, page, filter);
            const fomattedData = data
            yield put(getReclamationsSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* updateReclamation() {
    yield takeEvery(UPDATE_RECLAMATIONS, function* ({ payload }) {
        try {
            const reclamation = yield call(services.addUserData, payload);
            yield put(updateReclamationSuccess(reclamation));

        } catch (err) {
            console.log(err);
        }
    });
}



export default function* rootSaga() {
    yield all([
        fork(GetReclamation),
        fork(updateReclamation),
    ]);
}