
import { GET_CALENDAR, GET_CALENDAR_SUCCESS } from "redux/constants/Calendar";

const initState = {
    loading: false,
    calendar: null
}

const calendar = (state = initState, action) => {
    switch (action.type) {
        case GET_CALENDAR:
            return {
                ...state,
                loading: true
            }
        case GET_CALENDAR_SUCCESS:
            return {
                ...state,
                loading: false,
                calendar: action.payload
            }
        default:
            return state;
    }
}

export default calendar