import { GET_POS, GET_POS_SUCCESS, RESET_POS } from "redux/constants/POS";

export const getPos = () => {
    return {
        type: GET_POS
    };
};

export const getPosSuccess = (pos) => {
    return {
        type: GET_POS_SUCCESS,
        payload: pos
    }
};

export const resetPos = () => {
    return {
        type: RESET_POS
    };
};