import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	GET_ME_SUCCESS,
	REDIRECT_USER
} from '../constants/Auth';

const initState = {
	loading: false,
	error: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	profile: null
}
const redirectUSer = (role) => {
}
const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				error: false,
				message: "",
				loading: false,
				token: action.token
			}
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.err,
				error: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: AUTH_PREFIX_PATH,
				loading: false,
				profile: null
			}
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case GET_ME_SUCCESS: {
			return {
				...state,
				profile: action.payload,

			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case REDIRECT_USER: {
			return {
				...state,
				redirect: redirectUSer(action.role)
			}
		}
		default:
			return state;
	}
}

export default auth