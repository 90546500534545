import { GET_RECLAMATIONS, GET_RECLAMATIONS_SUCCESS, UPDATE_RECLAMATIONS, UPDATE_RECLAMATIONS_SUCCESS } from "redux/constants/Reclamations";

export const getReclamations = (page, filter) => {
    return {
        type: GET_RECLAMATIONS,
        page,
        filter
    };
};

export const getReclamationsSuccess = (reclamations) => {
    return {
        type: GET_RECLAMATIONS_SUCCESS,
        payload: reclamations
    }
};
export const updateReclamation = (reclamation) => {
    return {
        type: UPDATE_RECLAMATIONS,
        payload: reclamation
    }
};

export function updateReclamationSuccess(reclamation) {
    return {
        type: UPDATE_RECLAMATIONS_SUCCESS,
        payload: reclamation
    };
};

