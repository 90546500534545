import { GET_USERS, GET_USERS_SUCCESS, ADD_NEW_USER, ADD_NEW_USER_SUCCESS, DELETE_USER, DELETE_USER_SUCCESS } from "redux/constants/Users";

export const getUsers = (page, filter) => {
    return {
        type: GET_USERS,
        page,
        filter
    };
};

export const getUserSuccess = (users) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: users
    }
};
export const addNewUser = (user) => {
    return {
        type: ADD_NEW_USER,
        payload: user
    }
};

export function addNewUserSuccess(user) {
    return {
        type: ADD_NEW_USER_SUCCESS,
        payload: user
    };
};

export function deleteUserAction(user) {
    return {
        type: DELETE_USER,
        payload: user
    };
};

export function deleteUserSuccess(user) {
    return {
        type: DELETE_USER_SUCCESS,
        id: user.id
    };
};