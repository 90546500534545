
import { GET_GOVS,GET_GOVS_FRONT,GET_GOVS_SUCCESS,GET_FIELDS_FRONT,GET_FIELDS_SUCCESS,GET_CATEGORY_CONTACT,GET_CATEGORY_CONTACT_SUCCESS } from "redux/constants/Governoments";

const initState = {
    loading: false,
    govs: null,
    fields_app: null,
    category_contact : null
}

const govs = (state = initState, action) => {
    switch (action.type) {
        case GET_GOVS:
            return {
                ...state,
                loading: true
            }
        case GET_GOVS_FRONT:
            return {
                ...state,
                loading: true
            }            
        case GET_GOVS_SUCCESS:
            return {
                ...state,
                loading: false,
                govs: action.payload
            }
        case GET_FIELDS_FRONT:
            return {
                ...state,
                loading: true
            }            
        case GET_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                fields_app: action.payload
            }
        case GET_CATEGORY_CONTACT:
            return {
                ...state,
                loading: true
            }
        case GET_CATEGORY_CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                category_contact: action?.payload,
        }           
        default:
            return state;
    }
}

export default govs