import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_GOVS,GET_GOVS_FRONT,GET_FIELDS_FRONT,GET_CATEGORY_CONTACT
} from '../constants/Governoments';
import {
    getGovsSuccess,getFieldsSuccess,getCategoryContactSuccess
} from "../actions/Governoments";
import axios from 'axios';

import services from 'services/PrivateServices';

const baseUrl = process.env.REACT_APP_BASE_URL

const getGovernorate = () => axios.get(`${baseUrl}items/Governorate`, {
    params: {
      sort: "name"
    }
})

//Get Contact sub category
const getCategorysContact = () => axios.get(`${baseUrl}items/Sous_category_contact`, {
    params: {
        fields: "*,category.*",
        sort: "id"
    }
})

const getFieldsApplication = () => axios.get(`https://bridge.machrou3i.mliha.tn/webhook/application_fields`)

export function* GetGovernoments() {

    yield takeEvery(GET_GOVS, function* () {

        try {

            const data = yield call(services.getGovsData);

            const fomattedData = data.data

            yield put(getGovsSuccess(fomattedData))

        } catch (err) {



        }

    });


    yield takeEvery(GET_GOVS_FRONT, function* () {

        try {
            const data = yield call(getGovernorate);
            const fomattedData = data?.data?.data
            yield put(getGovsSuccess(fomattedData))

        } catch (err) {
        }
    });

    yield takeEvery(GET_CATEGORY_CONTACT, function* () {

        try {
            // const data = yield call(getCategorysContact);
            // const fomattedData = data?.data
            // yield put(getCategoryContactSuccess(fomattedData))

        } catch (err) {
        }
    });


    yield takeEvery(GET_FIELDS_FRONT, function* () {

        try {
            const data = yield call(getFieldsApplication);
            const fomattedData = data?.data?.data
            yield put(getFieldsSuccess(fomattedData))

        } catch (err) {
        }
    });

}


export default function* rootSaga() {
    yield all([
        fork(GetGovernoments),

    ]);
}
