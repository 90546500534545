import React, { useRef, useState, useCallback, useEffect } from 'react'
import Logo from 'assets/img/landing/logo.svg'
import Facebook from 'assets/img/landing/facebook.svg'
import Instagram from 'assets/img/landing/instagram.svg'
import LinkedIn from 'assets/img/landing/linkedin.svg'
import FacebookYellow from 'assets/img/landing/facebook-yellow.svg'
import InstagramYellow from 'assets/img/landing/instagram-yellow.svg'
import LinkedInYellow from 'assets/img/landing/linkedin-yellow.svg'
import People from 'assets/img/landing/header-people.svg'
import User from 'assets/img/landing/user.svg'
import VideoPlayer from 'assets/img/landing/video1.svg'
import ArrowRight from 'assets/img/landing/arrow-right.svg'
import ArrowLeft from 'assets/img/landing/arrow-left.svg'
import checkYellow from 'assets/img/landing/check-yellow.svg'
import checkGreen from 'assets/img/landing/check-green.svg'
import downloadYellow from 'assets/img/landing/download-file-yellow.svg'
import downloadGreen from 'assets/img/landing/download-file-green.svg'
import FranchiseImage from 'assets/img/landing/franchise-image.svg'
import FranchiseImageSm from 'assets/img/landing/franchise-image-sm.svg'
import GeranceImage from 'assets/img/landing/gerance-image.svg'
import GeranceImageSm from 'assets/img/landing/gerance-image-sm.svg'
import Person1 from 'assets/img/landing/nada khadhraoui.jpg'
import Person2 from 'assets/img/landing/Makrem Issaoui.jpeg'
import Person3 from 'assets/img/landing/Imen Badri.jpeg'
import Person4 from 'assets/img/landing/Tarek Marzouki.jpg'
import Person5 from 'assets/img/landing/Nabil Sayhi.jpeg'
import SendCand from 'assets/img/landing/sendCand.svg'
import QuoteUp from 'assets/img/landing/quote-up.svg'
import QuoteDown from 'assets/img/landing/quote-down.svg'
import Process1 from 'assets/img/landing/process-1.svg'
import Process2 from 'assets/img/landing/process-2.svg'
import Process3 from 'assets/img/landing/process-3.svg'
import Process4 from 'assets/img/landing/process-4.svg'
import Process5 from 'assets/img/landing/process-5.svg'
import Process6 from 'assets/img/landing/process-6.svg'
import Process7 from 'assets/img/landing/process-7.svg'
import Process8 from 'assets/img/landing/process-8.svg'
import Process9 from 'assets/img/landing/process-9.svg'
import Process10 from 'assets/img/landing/process-10.svg'
import { Button, Carousel, Col, Row } from 'antd'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { whyChooceMliha } from '../../assets/data/stepsHomeMliha';
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { setTypeApplication } from 'redux/actions/Landing'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { getGovsFront } from 'redux/actions/Governoments';

const innerWidth = window.innerWidth
const franchiseImage = innerWidth < 768 ? FranchiseImageSm : FranchiseImage
const geranceImage = innerWidth < 768 ? GeranceImageSm : GeranceImage
const testmonies = [
    {
        image: Person5,
        name: "Nabil Sayhi",
        job: "Gérant, PVKasserine",
        description: "J’ai contacté la société dans le cadre du projet Machrou3i pour la gérance d'un point de vente dans la région. L'équipe du projet 'a contacter de suite pour un entretien. ça fait déjà 16 mois que je fais partie de la famille MLIHA et j'en suis hyper fier. J'encourage tous les jeunes à y croire car honnêtement la société fait tout ce qui faut pour garantir la réussite de ses partenaires et collaborateurs."

    },
    {
        image: Person4,
        name: "Tarek Marzouki",
        job: "Franchisé, PV Fouchena",
        description: "Quand je dit MLIHA, Je dit Fiabilité, tout simplement parce que la Fiabilité est difficile à trouver. MLIHA, c'est une équipe sur laquelle vous pouvez compter, je suis reconnaissant pour tout leur travail. leur éthique de travail et leur implication sont admirable j'avais de la chance d'avoir quelqu'un d'aussi dévoué. Ils pensent toujours à des façons nouvelles et différentes, leur perspective unique est un atout formidable pour les clients... Mes mots ne suffit pas de faire l'éloge de leurs réalisations, la qualité de leur travail et le talent dont ils font preuve dépassent toujours mes attentes. Mliha, je recommande vivement."

    },
    {
        image: Person3,
        name: "Imen Badri",
        job: "Gérante, PV Mazzouna Sidi Bouzid",
        description: ".ايمان بدري 25 سنة صاحبة نقطة بيع مليحة مزونة سيدي بوزيد مشروعي بدا من حلمة اني نكون صاحبة مشروع و تحقق عندما سجلت في مشروعي مليحة .نشجع ناس كل تعمل كيفي باش تحقق الي تحلم بيه"

    },
    {
        image: Person1,
        name: "Nada Khadhraoui",
        job: "Gérante, PV Hassi Lefrid, Kasserine",
        description: ".ندى خضراوي 29 سنة من القصرين و انا منذ سنة شاركت في مشروعي و اتصلو بيا اعوان مليحة و انا تو صاحبة نقطة بيع مليحة و نشجع ناس كل باش تحل نقطة بيع مليحة "

    },
    {
        image: Person2,
        name: "Makrem Issaoui",
        job: "Gérant, PV Hamma Gabes",
        description: "Je m'appelle Makrem j'ai 25 ans je suis Gérant point de vente Mliha Hamma à Gabes. J'ai fait ma demande sur la plateforme Machrou3i de MLIHA, et l'équipe MLIHA m'ont assisté dès l'entretien jusqu'à l'ouverture. J'encourage tout le monde à déposer leur candidature et à commencer leur projet."

    }
]
const firstProcessus = [
    {
        icon: `/img/svg/process-1.svg`,
        title: "Premier contact à travers notre site \n web",
        number: "1"
    },
    {
        icon: `/img/svg/process-2.svg`,
        title: "1ère entrevue téléphonique",
        number: "2"
    },
    {
        icon: `/img/svg/process-3.svg`,
        title: "Visite sur place",
        number: "3"
    },
    {
        icon: `/img/svg/process-4.svg`,
        title: "Validation de l’emplacement",
        number: "4"
    },
    {
        icon: `/img/svg/process-5.svg`,
        title: "Validation du profil (entretien direct)",
        number: "5"
    },
]
const secondProcessus = [
    {
        icon: `/img/svg/process-6.svg`,
        title: "Demande de documentation détaillée",
        number: "6"
    },
    {
        icon: `/img/svg/process-7.svg`,
        title: "Préparation et signature du contrat",
        number: "7"
    },
    {
        icon: `/img/svg/process-8.svg`,
        title: "Visite Abattoir et formation",
        number: "8"
    },
    {
        icon: `/img/svg/process-9.svg`,
        title: "Planification des dates clés",
        number: "9"
    },
    {
        icon: `/img/svg/process-10.svg`,
        title: "Ouverture",
        number: "10"
    },
]
const TestimonyPreview = ({ person }) => <div className='testimony-preview d-flex' >

    <div className='d-flex justify-content-center align-items-center'>
        <img className='person-image' src={person.image} alt="person1" />
    </div>
    <div className='description flex-grow-1'>
        <div className='title font-family-din-alternative'>
            {person.name}
        </div>
        <div className='job d-flex justify-content-center justify-content-lg-between  align-items-center font-family-din-alternative'>
            {
                person.job
            }
            <div className='d-none d-lg-block'>
                <img src={QuoteUp} alt="QuoteUp" />
            </div>
        </div>
        <div className='text font-family-cairo description-avis'>
            {
                person.description
            }
        </div>
        <div className='d-none d-lg-block mt-3'>
            <img src={QuoteDown} alt="QuoteDown" />
        </div>
    </div>



</div>

const ProcessPreview = ({ process, index, className, isExtended }) => {
    const notIndex = className == "first" ? 4 : 0;
    const notIndexSmall = className == "first" ? 55 : 4
    const isWidthSmall = innerWidth < 991
    return <div className={clsx(`process-preview  ${className}`,
        { "decaled-process": !isWidthSmall && index % 2 === 1 },
        { "bottom-arrow": !isWidthSmall && index != notIndex && index % 2 === 0 },
        { "top-arrow": !isWidthSmall && index != notIndex && index % 2 === 1 },
        { "left-arrow": isWidthSmall && index != notIndexSmall && index % 2 === 0 && className == "first" && (index != 4 || (index === 4 && isExtended)) },
        { "right-arrow": isWidthSmall && index != notIndexSmall && index % 2 === 1 && className == "first" },
        { "left-arrow": isWidthSmall && index != notIndexSmall && index % 2 === 1 && className == "second" },
        { "right-arrow": isWidthSmall && index != notIndexSmall && index % 2 === 0 && className == "second" },
        { "mbc-40": isWidthSmall },
        { "blur-effect": index === 4 && className == "first" && !isExtended }
    )} >
        <div className='content d-flex flex-column'>
            <div className='icon'>
                <img src={process.icon} alt={`process-${process.number}`} />
            </div>
            <div className='text-center text'>
                {
                    process.title
                }
            </div>
            <div className='number'>
                {
                    process.number
                }
            </div>
        </div>
    </div>
}

const LandingHome = () => {
    const token = localStorage.getItem(AUTH_TOKEN)
    const videoPlayer = useRef(null)
    const carousel = useRef(null)
    const history = useHistory()
    const dispatch = useDispatch()
    const [isMuted, setisMuted] = useState(true)
    const [type, setType] = useState("FR")
    const [selectedFR, setselectedFR] = useState(0)
    const [selectedGE, setselectedGE] = useState(0)
    const [isProcessOpen, setIsProcessOpen] = useState(false)
    const currentYear = new Date().getFullYear();
    const [isLoadingCss, setisLoadingCss] = useState(true)
    const playVideo = () => {
        videoPlayer?.current.load()
        videoPlayer?.current.play()
        setisMuted(false)
    }

    /* Franchise */
    const advantagesVariablesFR = useCallback(
        () => {
            return whyChooceMliha.filter(item => item.step_parent === selectedFR && item.type === 'franchise')
        },
        [selectedFR],
    )
    /* Gérance */
    const advantagesVariablesGE = useCallback(
        () => {
            return whyChooceMliha.filter(item => item.step_parent === selectedGE && item.type === 'gerance')
        },
        [selectedGE],
    )
    const onChangeCarousel = (step) => {
        const current = carousel?.current
        step === "next" ? current.next() : current.prev()
    }
    const secondTable = () => {
        const innerWidthlocal = window.innerWidth
        if (innerWidthlocal < 991) return secondProcessus
        return secondProcessus[0].number === "10" ? secondProcessus : secondProcessus.reverse()
    }

    const onApply = (type = "") => {
        dispatch(setTypeApplication(type))
        history.push(`/application`)
    }
    useEffect(() => {
        setTimeout(() => {
            setisLoadingCss(false)
        }, 2000);
    }, [])

    const onLogin = () => history.push(token ? APP_PREFIX_PATH : AUTH_PREFIX_PATH)
    return (
        <>
            {
                isLoadingCss ? <div className='loading-home'>
                    <img src={Logo} alt="" />
                </div> : <>
                    <div className='landing-header' >
                        <div className='container ' >
                            <div className='d-flex justify-content-between align-items-center navbar-landing'>
                                <div className='d-flex justify-content-center' >
                                    <a href="/" title='Mliha Machrou3i' className='link-logo'><img src={Logo} alt="logo" /></a>
                                </div>
                                <div className='d-flex'>
                                    <div className='d-md-flex d-none social align-items-center'>
                                        <div className='font-weight-bold pointer mrc-2'>
                                            <Link to="/contact" className='link-faq' title='FAQ'>Contactez-nous</Link>
                                        </div>
                                        <div className='pointer mrc-2'>
                                            <a href="https://www.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={Facebook} alt="facebook" /></a>
                                        </div>
                                        <div className='pointer mrc-2' >
                                            <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={Instagram} alt="instagram" /></a>
                                        </div>
                                        <div className='pointer' >
                                            <a href="https://www.linkedin.com/company/mliha/" className='link-inline-block' target="_blank" rel="noreferrer" title='LinkedIn'><img width={30} height={30} src={LinkedIn} alt="LinkedIn" /></a>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center actions'>
                                        <div className='connect pointer' onClick={onLogin}>
                                            <img src={User} alt="user" />
                                        </div>
                                        <Button type='primary' className='custom-button green-button font-weight-bold' onClick={() => onApply()}>
                                            Candidature
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col md={10} className="d-flex flex-column justify-content-center">
                                    <h1 className='big-title'>
                                        <span className='font-family-din-pro font-weight-light'>Mliha, une entreprise 100% <br className='d-none d-lg-block' /> tunisienne de</span>  <span className='font-weight-bold'>30 ans d’expérience.</span>
                                    </h1>
                                    <p className='description mt-2'>
                                        Nous vous accompagnons durant tout le processus <br className='d-none d-lg-block' />  d’ouverture du PDV et après <br className='d-none d-lg-block' />  l'ouverture.
                                    </p>
                                    <div className='d-flex  align-items-center w-fit-content pointer' onClick={playVideo}>
                                        <div className='video-player '>
                                            <img width={"100%"} height={"100%"} src={VideoPlayer} alt="player" />
                                        </div>

                                        <div className='ml-2 text-video font-family-din-alternative'>Lire la vidéo</div>
                                    </div>
                                </Col>
                                <Col md={14} className="mt-3 mt-md-0 d-flex min-height-video">
                                    <video ref={videoPlayer} className='big-image' poster="/docs/header-people.png" autoPlay={true} muted={isMuted}>
                                        <source src="/docs/video-mliha.mp4" type="video/mp4" />
                                    </video>
                                </Col>
                            </Row>

                        </div>
                    </div>
                    <div className='section-1 container'>
                        <h2>Pourquoi choisir Mliha ?</h2>
                        <div className='d-flex  justify-content-center'>
                            <div className='d-flex font-family-din-alternative buttons-container'>
                                <div className={clsx('adventages-button pointer', { "colored": type === "FR" })} onClick={() => { setType("FR") }}>
                                    Franchise
                                </div>
                                <div className={clsx('adventages-button pointer', { "colored": type === "GL" })} onClick={() => { setType("GL") }}>
                                    Gérance
                                </div>
                            </div>
                        </div>
                        {/* Franchise */}
                        {type === "FR" ? <div className='slides'>
                            <Row gutter={[8]}>
                                <Col xs={8} className={clsx('d-flex justify-content-center  steps-containter font-family-din-pro', { "selected": selectedFR === 0 })}>
                                    <div className='text-center steps pointer' onClick={() => setselectedFR(0)}>
                                        <span className='font-weight-bold'>
                                            avant <br />
                                        </span>
                                        <span>
                                            L’ouverture
                                        </span>
                                    </div>
                                </Col>
                                <Col xs={8} className={clsx('d-flex justify-content-center  steps-containter font-family-din-pro', { "selected": selectedFR === 1 })}>
                                    <div className='text-center steps pointer' onClick={() => setselectedFR(1)}>
                                        <span className='font-weight-bold'>
                                            pendant <br />
                                        </span>
                                        <span>
                                            L’ouverture
                                        </span>
                                    </div>
                                </Col >
                                <Col xs={8} className={clsx('d-flex justify-content-center steps-containter font-family-din-pro', { "selected": selectedFR === 2 })}>
                                    <div className='text-center steps pointer' onClick={() => setselectedFR(2)}>
                                        <span className='font-weight-bold'>
                                            Après  <br />
                                        </span>
                                        <span>
                                            L’ouverture
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='mt-5 d-block d-md-flex' gutter={[24]} >
                                {
                                    advantagesVariablesFR().map((el, index) => <Col className='mt-4 mb-4 mt-lg-0' key={index} md={12} lg={advantagesVariablesFR()?.length > 3 ? 6 : 8}>
                                        <div className='custom-card'>
                                            <img src={el.icon} alt="" />
                                            <div className='text'>
                                                {
                                                    el.text
                                                }
                                            </div>
                                        </div>

                                    </Col>)
                                }
                            </Row>
                        </div>
                            :
                            /* Gérance */
                            <div className='slides'>
                                <Row gutter={[8]}>
                                    <Col xs={8} className={clsx('d-flex justify-content-center  steps-containter font-family-din-pro', { "selected": selectedGE === 0 })}>
                                        <div className='text-center steps pointer' onClick={() => setselectedGE(0)}>
                                            <span className='font-weight-bold'>
                                                avant <br />
                                            </span>
                                            <span>
                                                L’ouverture
                                            </span>
                                        </div>
                                    </Col>
                                    <Col xs={8} className={clsx('d-flex justify-content-center  steps-containter font-family-din-pro', { "selected": selectedGE === 1 })}>
                                        <div className='text-center steps pointer' onClick={() => setselectedGE(1)}>
                                            <span className='font-weight-bold'>
                                                pendant <br />
                                            </span>
                                            <span>
                                                L’ouverture
                                            </span>
                                        </div>
                                    </Col >
                                    <Col xs={8} className={clsx('d-flex justify-content-center steps-containter font-family-din-pro', { "selected": selectedGE === 2 })}>
                                        <div className='text-center steps pointer' onClick={() => setselectedGE(2)}>
                                            <span className='font-weight-bold'>
                                                Après  <br />
                                            </span>
                                            <span>
                                                L’ouverture
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mt-5 d-block d-md-flex' gutter={[24]} >
                                    {
                                        advantagesVariablesGE().map((el, index) => <Col className='mt-4 mb-4 mt-lg-0' key={index} md={12} lg={advantagesVariablesGE()?.length > 3 ? 6 : advantagesVariablesGE().length == 2 ? 12 : 8}>
                                            <div className='custom-card'>
                                                <img src={el.icon} alt="" />
                                                <div className='text'>
                                                    {
                                                        el.text
                                                    }
                                                </div>
                                            </div>

                                        </Col>)
                                    }
                                </Row>
                            </div>
                        }
                    </div>


                    <div className='section-2 '>

                        <div className='container pt-5'>
                            <Row gutter={[16]}>
                                <Col xs={24} md={24} lg={8} className="d-flex  justify-content-center w-100  align-items-start">
                                    <img className=' mt-0 mt-md-4 indicator-image' src={franchiseImage} alt="store" />
                                </Col>
                                <Col xs={24} md={24} lg={16} >
                                    <div className='type-tag FR mt-4 mt-md-0'>
                                        Franchise
                                    </div>
                                    <h2 >
                                        Vous voulez <span>lancer</span>  <br className='d-none d-md-block' />
                                        un point de vente Mliha ?
                                    </h2>
                                    <ul className='list-unstyled '>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Pour lancer son point de vente Mliha, il faut bien sûr convaincre la marque qu'on est le candidat idéal. L'enseigne souhaite des partenaires de confiance et motivés.
                                            </div>
                                        </li>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Le candidat est un véritable entrepreneur capable de créer et de gérer un point de vente.
                                            </div>
                                        </li>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                De plus, ce partenaire franchisé doit disposer d’une solide expérience de chef d’entreprise doublée de solides qualités de management et de capacités financières pouvant accompagner son développement.                                    </div>
                                        </li>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Vous avez une personnalité dynamique et désireuse de relever des challenges.
                                            </div>
                                        </li>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Vous avez une aptitude à encadrer où animer une équipe.
                                            </div>
                                        </li>
                                        <li className='list-item FR'>
                                            <div>
                                                <img src={checkYellow} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Vous êtes prêts à vous investir dans un secteur très opérationnel.
                                            </div>
                                        </li>
                                    </ul>
                                    <p className='mt-2 font-weight-normal'>
                                        Vous avez une expérience significative en gestion, alors votre profil nous intéresse...
                                    </p>
                                    <Button type='primary' className='custom-button yellow-button font-weight-bold' onClick={() => onApply("FR")} >
                                        Candidature
                                    </Button>
                                    <div className='download-file FR d-flex align-items-center font-weight-normal'>
                                        <a href="/docs/modelfranchise.pdf" target="_blank" title="Télécharger le model Franchise">
                                            <img src={downloadYellow} alt="downloadYellow" />
                                            <span>
                                                Télécharger le model Franchise
                                            </span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div className='section-3'>
                        <div className='container'>
                            <Row gutter={[16]}>
                                <Col xs={24} md={24}  lg={8} className="d-flex justify-content-center w-100  align-items-start">
                                    <img className=' indicator-image' src={geranceImage} alt="store" />
                                </Col>
                                <Col xs={24} md={24}  lg={16}>
                                    <div className='type-tag GL mt-4'>
                                        Gérance
                                    </div>
                                    <h2 >
                                        Vous voulez <span>gérer</span>  <br className='d-none d-md-block' />
                                        un point de vente Mliha ?
                                    </h2>
                                    <ul className='list-unstyled '>
                                        <li className='list-item GL'>
                                            <div>
                                                <img src={checkGreen} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Pour gérer un point de vente Mliha, il faut bien sûr convaincre la marque qu'on est le candidat idéal. L'enseigne souhaite des partenaires de confiance et motivés.
                                            </div>
                                        </li>
                                        <li className='list-item GL'>
                                            <div>
                                                <img src={checkGreen} alt="check-yellow" />
                                            </div>
                                            <div>
                                                Le candidat est un véritable entrepreneur capable de gérer un point de vente. Ayant une personnalité dynamique et désireuse de relever des challenges, une aptitude à encadrer où animer une équipe
                                            </div>
                                        </li>
                                        <li className='list-item GL'>
                                            <div>
                                                <img src={checkGreen} alt="check-yellow" />
                                            </div>
                                            <div>
                                                De plus, le gérant doit disposer de solides qualités de management et de capacités financières pour le cautionnement relatif au PDV complétement équipé et d’un fond de roulement pour financer le démarrage de l’activité.
                                            </div>
                                        </li>

                                    </ul>
                                    <p className='mt-2'>
                                        Vous avez une expérience significative en gestion, alors votre profil nous intéresse...
                                    </p>
                                    <Button type='primary' className='custom-button green-button font-weight-bold' onClick={() => onApply("GL")}>
                                        Candidature
                                    </Button>
                                    <div className='download-file GL d-flex align-items-center pointer'>
                                        <a href="/docs/modelgerance.pdf" target="_blank" title="Télécharger le model Gérance">

                                            <img src={downloadGreen} alt="downloadYellow" />
                                            <span>
                                                Télécharger le model Gérance
                                            </span>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='section-4'>
                        <div className="container">
                            <h2 className='text-center text-uppercase'>
                                Processuss de sélection
                            </h2>
                            {/* <p className='text-center'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet <br className='d-none d-lg-block' /> non ornare purus nunc, eget id ut elementum pellentesque.
                            </p> */}
                        </div>
                        <div className='circles-container container mt-5' >
                            <div className={clsx(' d-flex flex-lg-row flex-column  justify-content-center align-items-center', { "first-continer": innerWidth > 991 })}>
                                {
                                    firstProcessus.map((el, index) => <ProcessPreview key={index} process={el} index={index} className={"first"} isExtended={isProcessOpen} />)
                                }
                            </div>
                            <div className={clsx('flex-lg-row flex-column  second-process-container justify-content-center align-items-center ', { 'open-process': innerWidth > 991 || isProcessOpen }, { 'close-process': !isProcessOpen && innerWidth < 991 })}  >
                                {
                                    secondTable().map((el, index) => <ProcessPreview key={index} process={el} index={index} className={"second"} />)
                                }
                            </div>
                            <div className='see_more d-lg-none '>
                                <span className=' pointer' onClick={() => setIsProcessOpen(!isProcessOpen)}>{
                                    `Voir  ${isProcessOpen ? "moins" : "plus"}`
                                }</span>
                            </div>
                        </div>
                    </div>
                    <div className="section-5">
                        <div className='container-feedback'>
                            <h2 className='text-center text-uppercase'>
                                Témoignage
                            </h2>
                            <Row gutter={[20]}>
                                <Col xs={0} lg={2}>
                                    <div className='d-flex justify-content-center align-items-center h-100 '>
                                        <img width={17} height={29} src={ArrowLeft} className="pointer" alt="ArrowLeft" onClick={() => onChangeCarousel("prev")} />
                                    </div>
                                </Col>
                                <Col xs={24} lg={20} className="slider-bottom-home">
                                    <Carousel ref={carousel} autoplay dots={false} speed={1000} autoplaySpeed={5000}>
                                        {
                                            testmonies.map((el, index) => <TestimonyPreview key={index} person={el} />)
                                        }
                                    </Carousel>
                                </Col>
                                <Col xs={0} lg={2} >
                                    <div className='d-flex justify-content-center align-items-center h-100 '>
                                        <img src={ArrowRight} width={17} height={29} className="pointer" alt="ArrowRight" onClick={() => onChangeCarousel("next")} />
                                    </div>
                                </Col>
                                <Col xs={24} lg={0} className="d-flex justify-content-center mt-5 d-lg-none">
                                    <div className='d-flex align-items-center'>
                                        <img src={ArrowLeft} alt="ArrowLeft" className='d-block mr-5 pointer' onClick={() => onChangeCarousel("prev")} />
                                        <img src={ArrowRight} alt="ArrowRight" className='d-block ml-5 pointer' onClick={() => onChangeCarousel("next")} />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>
                    <div className='landing-footer'>
                        <div className='container'>
                            <div className='yellow-card d-flex flex-column justify-content-center align-items-center '>
                                <div className='indicator'>
                                    <img src={SendCand} alt="SendCand" />
                                </div>
                                <h2 className='mb-5'>
                                    SOUMETTRE VOTRE CANDIDATURE
                                </h2>
                                {/* <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet <br className='d-none d-md-block' /> non ornare purus nunc, eget id ut elementum pellentesque.
                                </p> */}
                                <Button type='primary' style={{
                                    maxWidth: 200,
                                    alignSelf: "center"
                                }} className='custom-button green-button font-weight-bold' onClick={() => onApply()} >Candidature</Button>
                            </div>
                            <div className='faq'>
                                <div className='d-none d-md-block'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='text footer-copyRight'>
                                            © {currentYear} Mliha Tous droits réservés
                                        </div>
                                        <div className='social-network'>
                                            <a href="https://www.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={FacebookYellow} alt="FacebookYellow" className='mr-3 pointer' /></a>
                                            <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={InstagramYellow} alt="InstagramYellow" className='mr-3 ml-2 pointer' /></a>
                                            <a href="https://www.linkedin.com/company/mliha/" className='link-inline-block' target="_blank" rel="noreferrer" title="LinkedIn"><img width={30} height={30} src={LinkedInYellow} alt="LinkedInYellow" className='ml-3 pointer' /></a>
                                        </div>
                                        <div className='text link-footer-bottom footer-links-contact'>
                                            <Link className='link-footer' to="/contact" title='Contact'>Contactez-nous</Link>
                                            {/* -
                                            <Link className='link-footer' to="/" title='Conditions générales'>Conditions générales</Link> */}
                                        </div>
                                    </div>

                                </div>

                                <div className='d-md-none d-flex text-center  d-flex flex-column align-items-center'>
                                    <div className='mb-4 mb-md-0'>
                                        <a href="https://wlinww.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={FacebookYellow} alt="FacebookYellow" className='mr-2 pointer' /></a>
                                        <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={InstagramYellow} alt="InstagramYellow" className='ml-2 pointer' /></a>
                                    </div>


                                    <div className='text mb-4 mb-md-0  link-footer-bottom'>
                                        <Link className='link-footer' to="/contact" title='Contact'>Contactez-nous</Link>
                                    </div>
                                    <div className='text '>
                                        © {currentYear} Mliha Tous droits réservés
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }


        </>
    )
}

export default LandingHome