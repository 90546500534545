import { GET_APPLICATION_STATUS, GET_APPLICATION_STATUS_SUCCESS } from "redux/constants/ApplicationStatus";

export const getApplicationStatus = () => {

    return {
        type: GET_APPLICATION_STATUS
    };
};

export const getApplicationStatusSuccess = (application) => {
    return {
        type: GET_APPLICATION_STATUS_SUCCESS,
        payload: application
    }
};
