import Logo from 'assets/img/landing/logo.svg'
import Facebook from 'assets/img/landing/facebook.svg'
import Instagram from 'assets/img/landing/instagram.svg'
import LinkedIn from 'assets/img/landing/linkedin.svg'
import User from 'assets/img/landing/user.svg'
import { Button } from 'antd'
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { AUTH_TOKEN } from 'redux/constants/Auth'
export const HeaderFront = () => {
    const history = useHistory();
    const token = localStorage.getItem(AUTH_TOKEN)
    const onApply = () => {
        history.push(`/application`)
    }
    const onLogin = () => history.push(token ? APP_PREFIX_PATH : AUTH_PREFIX_PATH)
    return (
        <div className='landing-header top' >
            <div className='container ' >
                <div className='d-flex justify-content-between align-items-center navbar-landing top'>
                    <div className='d-flex justify-content-center' >
                        <Link to="/" title='Mliha'><img src={Logo} alt="logo" /></Link>
                    </div>
                    <div className='d-flex'>
                        <div className='d-md-flex d-none social align-items-center'>
                            <div className='font-weight-bold pointer mrc-2'>
                                {history?.location.pathname !== "/contact" && <Link className='link-faq' to="/contact" title='contact'>Contactez-nous</Link>}
                            </div>
                            <div className='pointer mrc-2'>
                                <a href="https://www.facebook.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title="Facebook"><img src={Facebook} alt="facebook" /></a>
                            </div>
                            <div className='pointer mrc-2' >
                                <a href="https://www.instagram.com/mlihatunisie/" className='link-inline-block' target="_blank" rel="noreferrer" title='Instagram'><img src={Instagram} alt="instagram" /></a>
                            </div>
                            <div className='pointer' >
                                <a href="https://www.linkedin.com/company/mliha/" className='link-inline-block' target="_blank" rel="noreferrer" title="LinkedIn"><img width={30} height={30} src={LinkedIn} alt="Linkedin" className='pointer' /></a>
                            </div>
                        </div>
                        <div className='d-flex align-items-center actions'>

                            <div className='connect pointer' onClick={onLogin}>
                                <img src={User} alt="user" />
                            </div>
                            <Button type='primary' className='custom-button green-button' onClick={() => onApply()}>
                                Candidature
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}