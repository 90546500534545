
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    ADD_NEW_USER,
    ADD_NEW_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS
} from '../constants/Users';

const initState = {
    loading: false,
    users: null,
    count: null,
}

const deleteUser = (user, userIdToDelete) => {
    const newUser = [...user]
    const id = newUser.map(el => el.id).indexOf(userIdToDelete)
    newUser.splice(id, 1)
    return newUser
}

const users = (state = initState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true
            }
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.data,
                count: action.payload?.meta?.filter_count
            }
        case ADD_NEW_USER:
            return {
                ...state,
                loading: true
            }
        case ADD_NEW_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                users: [action.users, ...state.users],
                count: state.count++

            }
        case DELETE_USER:
            return {
                ...state,
                loading: true
            }
        case DELETE_USER_SUCCESS:
            return {
                loading: false,
                deleted: true,
                users: deleteUser(state.users, action.id),
                count: state.count--
            }
        default:
            return state;
    }
}

export default users