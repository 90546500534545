
import { GET_FORM_FIELDS, GET_FORM_FIELDS_SUCCESS } from "redux/constants/FormFields";

const initState = {
    loading: false,
    
}

const formFields = (state = initState, action) => {
    switch (action.type) {
        case GET_FORM_FIELDS:
            return {
                ...state,
                loading: true
            }
        case GET_FORM_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                [action.formType]: action.fields
            }
        default:
            return state;
    }
}

export default formFields