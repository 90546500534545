import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
	const currentYear = new Date().getFullYear();
	return (
		<footer className="footer">
			<span>© {currentYear} Mliha Tous droits réservés</span>
		</footer>
	)
}

