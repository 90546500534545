import { GET_CALENDAR, GET_CALENDAR_SUCCESS } from "redux/constants/Calendar";

export const getCalendar = () => {
    return {
        type: GET_CALENDAR
    };
};

export const getCalendarSuccess = (calendar) => {
    return {
        type: GET_CALENDAR_SUCCESS,
        payload: calendar
    }
};
