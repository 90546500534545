import {
SET_TYPE_APPLICATION
} from '../constants/Landing';

const initState = {
  type: "",
}

const landing = (state = initState, action) => {
	switch (action.type) {
		case SET_TYPE_APPLICATION:
			return {
				...state,
				type: action.applicationType
			}
		default:
			return state;
	}
}

export default landing