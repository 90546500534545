import Card_1 from 'assets/img/landing/card-1.svg'
import Card_2 from 'assets/img/landing/card-2.svg'
import Card_3 from 'assets/img/landing/card-3.svg'
import Card_4 from 'assets/img/landing/card-4.svg'

export const whyChooceMliha = [
    {   
        type:'franchise',
        step_parent : 0,
        etape: '1',
        icon : Card_1,
        text: 'L\'évaluation du potentiel point de vente'
    },
    {
        type:'franchise',
        step_parent : 0,
        etape: '2',
        icon : Card_2,
        text: 'La fourniture des éléments de la charte graphique propre à l\'enseigne'   
    },
    {
        type:'franchise',
        step_parent : 0,
        etape: '3',
        icon : Card_3,
        text: 'Votre formation ainsi que celle de votre équipe, aussi bien sur le plan théorique (au sein de la société) que pratique (stage de formation dans un point de vente type'   
    },
    {
        type:'franchise',
        step_parent : 1,
        etape: '1',
        icon : Card_1,
        text: 'La présence de l\'équipe Mliha à l\'ouverture du magasin'
    },
    {
        type:'franchise',
        step_parent : 1,
        etape: '2',
        icon : Card_2,
        text: 'La préparation de la commande type d\'ouverture'   
    },
    {
        type:'franchise',
        step_parent : 1,
        etape: '3',
        icon : Card_3,
        text: 'Les actions d\'animation et de promotion pour générer du trafic dans le point de vente'   
    },
    {
        type:'franchise',
        step_parent : 2,
        etape: '1',
        icon : Card_1,
        text: 'Une aide à l\'exploitation, la gestion et la vente par le suivi des achats et ventes mensuelles du point de vente, le suivi des commandes .etc.'
    },
    {
        type:'franchise',
        step_parent : 2,
        etape: '2',
        icon : Card_2,
        text: 'La livraison des commandes dans un délai maximum de 24 h'   
    },
    {
        type:'franchise',
        step_parent : 2,
        etape: '3',
        icon : Card_3,
        text: 'Les actions de formation continue pour le recyclage du personnel'   
    },
    {
        type:'franchise',
        step_parent : 2,
        etape: '4',
        icon : Card_4,
        text: 'Le soutien publicitaire intense à travers une communication continue sur toute l\'année (TV, radio, affichage. etc.)'   
    },
    //Gérance
    {
        type:'gerance',
        step_parent : 0,
        etape: '1',
        icon : Card_1,
        text: 'La mise à disposition d’un point de vente complétement équipé'   
    },
    {
        type:'gerance',
        step_parent : 0,
        etape: '2',
        icon : Card_2,
        text: 'Votre formation ainsi que celle de votre équipe, aussi bien sur le plan théorique (au sein de la société) que pratique (stage de formation dans un point de vente type'   
    },
    {
        type:'gerance',
        step_parent : 1,
        etape: '1',
        icon : Card_1,
        text: 'La présence de l\'équipe Mliha à l\'ouverture du magasin'   
    },
    {
        type:'gerance',
        step_parent : 1,
        etape: '2',
        icon : Card_2,
        text: 'La préparation de la commande type d\'ouverture'   
    },
    {
        type:'gerance',
        step_parent : 1,
        etape: '3',
        icon : Card_3,
        text: 'L\'assistance technique pour la mise en marche du matériel'   
    },
    {
        type:'gerance',
        step_parent : 1,
        etape: '4',
        icon : Card_4,
        text: 'Les actions d\'animation et de promotion pour générer du trafic dans le point de vente'   
    },
    {
        type:'gerance',
        step_parent : 2,
        etape: '1',
        icon : Card_1,
        text: 'Une aide à l\'exploitation, la gestion et la vente par le suivi des achats et ventes mensuelles du point de vente, le suivi des commandes .etc.'   
    },
    {
        type:'gerance',
        step_parent : 2,
        etape: '2',
        icon : Card_2,
        text: 'La livraison des commandes dans un délai maximum de 24 h'   
    },
    {
        type:'gerance',
        step_parent : 2,
        etape: '3',
        icon : Card_3,
        text: 'L\'assistance technique pour l\'entretien et la maintenance du matériel'   
    },
    {
        type:'gerance',
        step_parent : 2,
        etape: '4',
        icon : Card_4,
        text: 'Les actions de formation continue pour le recyclage du personnel'   
    },
    {
        type:'gerance',
        step_parent : 2,
        etape: '5',
        icon : Card_4,
        text: 'Le soutien publicitaire intense à travers une communication continue sur toute l\'année (TV, radio, affichage. etc.)'   
    }
];