
import { GET_APPLICATION_STATUS, GET_APPLICATION_STATUS_SUCCESS } from "redux/constants/ApplicationStatus";

const initState = {
    loading: false,
    application: null
}

const applicationStatus = (state = initState, action) => {
    switch (action.type) {
        case GET_APPLICATION_STATUS:
            return {
                ...state,
                loading: true
            }
        case GET_APPLICATION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                application: action.payload
            }
        default:
            return state;
    }
}

export default applicationStatus