import { GET_FORM_FIELDS, GET_FORM_FIELDS_SUCCESS } from "redux/constants/FormFields";

export const getFormFields = (formType) => {
    return {
        type: GET_FORM_FIELDS,
        formType
    };
};

export const getFormFieldsySuccess = (formType,fields) => {
    return {
        type: GET_FORM_FIELDS_SUCCESS,
        formType,
        fields
    }
};
