import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  SettingOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { Link } from "react-router-dom";
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from "react-redux";
import Utils from "utils";

const menuItem = [
    {
		title: "Paramètres",
		icon: SettingOutlined,
		path: "settings/change-password"
    }
]

export const NavProfile = ({signOut}) => {
  const {profile} = useSelector(state => state.auth) 
  const profileImg =  Utils.getAssetFullUrl(profile?.avatar);
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
     
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <Link to={`${APP_PREFIX_PATH}/`+el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal">Déconnexion</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <div className="nav-profile-header">
          <div className="d-flex avatar-flex-header">
            <Avatar size={45} src={profileImg} />
            <div className="avater-name">
              <h4 className="mb-0">{Utils.getFullName(profile)}</h4>
              <span className="text-muted">{profile?.role.name || Utils.setLocale("user_machrou3i")}</span>
            </div>
          </div>
          </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
