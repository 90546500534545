import React from 'react'
import { Switch, Route, } from "react-router-dom";
import AuthViews from 'views/auth-views'

export const AuthLayout = (props) => {
	const { location } = props

	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={() => <AuthViews location={location} />} />
			</Switch>
		</div>
	)
}


export default AuthLayout
