import { useEffect,useState } from "react";
import { Row, Collapse } from 'antd';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom'
import Loading from 'components/shared-components/Loading';
import { ReactComponent as House } from 'assets/img/landing/house.svg';
import NotFound from 'assets/img/landing/not-found.png';
const PageNotFound = ({showButton = false}) => {    
    const history = useHistory();
    const [loader,setLoader] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 1500);
    }, [])

    return(
        <div className={showButton ? "page-not-found" : "page-not-found height100"}>
        {loader  ? <div className="loader_full_page"><Loading /></div> :
        <Row gutter={16}>
            <Result
                icon={<img className='not-found-image' src={NotFound} alt="person1" />}
                title="404"
                subTitle="Désolé, la page visitée n'existe pas."
                extra={showButton ? <Button className='felecitation-button' icon={<House />} onClick={() => history.push('/')}>
                Retour à la page d’accueil
              </Button> : null}
            />
        </Row>}
        </div>
    );

}

export default PageNotFound