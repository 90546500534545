import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Users from './Users';
import Landing from './Landing';
import Roles from './Roles'
import Dashboard from './Dashboard'
import Governoments from './Governoments'
import Applciations from './Applications'
import Calendar from './Calendar'
import ApplicationHistory from './ApplicationHistory'
import ApplicationStatus from './ApplicationStatus'
import Pos from './POS'
import Company from './CompanyType'
import FormFields from './FormFields'
import Reclamations from './Reclamations'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    landing: Landing,
    users: Users,
    roles: Roles,
    govs: Governoments,
    applications: Applciations,
    calendar: Calendar,
    applicationHistory: ApplicationHistory,
    applicationStatus: ApplicationStatus,
    pos: Pos,
    company: Company,
    reclamations : Reclamations,
    formFields: FormFields,
    dashboard: Dashboard
});

export default reducers;