import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Logo from "assets/img/landing/logo.svg";

function Closed() {
  useEffect(() => {
    document.body.className="placeholder-white"
  }, [])
  
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="application-header d-flex justify-content-center align-items-center"
        style={{ position: "fixed", top: 0 ,width:"100%"}}
      >
        <Link to="/" title="Mliha Machrou3i">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <div style={{height:'inherit',marginTop:200}}>
        <h1 className="text-center mt-5 mb-4" style={{ fontSize: 30 }}>
          Merci d'avoir choisi MLIHA.
        </h1>
        <p
          style={{ fontSize: 22}}
          className="text-center mb-0"
        >
        Nous sommes actuellement en train de traiter toutes les candidatures reçues durant la 3<sup>ème</sup> vague. La plateforme machrou3i sera ouverte pour une 4<sup>ème</sup> vague de dépôt des dossiers pour les formules Gérance Libre et Franchise du <u>01/10/2024</u> au <u>31/10/2024</u>.
        </p>
        <p
          style={{ fontSize: 22}}
          className="text-center"
        >
        Les candidats qui déposeront leurs candidatures durant cette période, seront rappelés du <u>20/10/2024</u> au <u>30/12/2024</u>.
        </p>
        <p
          style={{ fontSize: 22}}
          className="text-center"
        >
          Merci pour votre confiance.
        </p>
      </div>
    </div>
  );
}

export default Closed;
