import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {GET_APPLICATIONS_DASHBOARD} from '../constants/Dashboard';
import {getApplicationsDashboardSuccess} from "../actions/Dashboard";
import services from 'services/PrivateServices';

export function* GetApplicationsDashboard() {

    yield takeEvery(GET_APPLICATIONS_DASHBOARD, function* () {

        try {
            const data = yield call(services.getDashboardApplications);
            const fomattedData = data
            const data_user = yield call(services.getAllUserRdr);
            const fomattedDataUsers = data_user
            yield put(getApplicationsDashboardSuccess(fomattedData,fomattedDataUsers))

        } catch (err) {

        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(GetApplicationsDashboard),
    ]);
}