import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_ROLES
} from '../constants/Role';
import {
    getRoleSuccess
} from "../actions/Role";


import services from 'services/PrivateServices';




export function* GetRole() {

    yield takeEvery(GET_ROLES, function* () {

        try {

            const data = yield call(services.getRoleData);

            const fomattedData = data.data

            yield put(getRoleSuccess(fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetRole),

    ]);
}
