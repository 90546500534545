
import {
    GET_APPLICATIONS_DASHBOARD,
    GET_APPLICATIONS_SUCCESS_DASHBOARD ,
} from '../constants/Dashboard';

const initState = {
    loading: true,
    allApplications: null,
    count: null,
    rdr: null,
    loading_rdr: true,
}



const dashboard = (state = initState, action) => {
    switch (action.type) {
        case GET_APPLICATIONS_DASHBOARD:
            return {
                ...state,
                loading: true
            }
        case GET_APPLICATIONS_SUCCESS_DASHBOARD:
            return {
                ...state,
                loading: false,
                allApplications: action?.applications?.data,
                count: action?.applications?.data?.length,
                rdr: action?.users?.data,
            }
        default:
            return state;
    }
}

export default dashboard