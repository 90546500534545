import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_FORM_FIELDS
} from '../constants/FormFields';
import {
    getFormFieldsySuccess
} from "../actions/FormsFields";


import services from 'services/PrivateServices';




export function* GetFormFields() {

    yield takeEvery(GET_FORM_FIELDS, function* ({ formType }) {

        try {

            const data = yield call(services.getFormFileds, formType);
            const fomattedData = data?.data
            yield put(getFormFieldsySuccess( formType,fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetFormFields),

    ]);
}
