import { useState, useEffect } from "react";
import { HeaderFront } from "components/layout-components/HeaderFront"
import { FooterFront } from "components/layout-components/FooterFront";
import { Col, Row, Input, Select, Button, message } from 'antd';
import { ReactComponent as Civility } from 'assets/img/landing/civility.svg';
import { ReactComponent as SelectArrow } from 'assets/img/landing/select-arrow.svg';
import { ReactComponent as UserApplication } from 'assets/img/landing/user-application.svg';
import { ReactComponent as EmailApplication } from 'assets/img/landing/email-application.svg';
import { ReactComponent as PhoneApplication } from 'assets/img/landing/phone.svg';
import { ReactComponent as TN } from 'assets/img/landing/TN.svg';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import clsx from 'clsx';
import publicServices from 'services/PublicService';


const Contact = () => {
  const { Option } = Select
  const { TextArea } = Input;
  //Form Schema
  const FormSchema = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    category: "",
    message: "",
  }
  const FormSchemaValidation = {
    first_name: true,
    last_name: true,
    email: true,
    phone_number: true,
    category: true,
    message: true,
  }

  const [formFields, setFormFields] = useState(FormSchema)
  const [validation, setValidation] = useState(FormSchemaValidation)
  const [loader, setloader] = useState(false)

  // 👇️ scroll to top on page load
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // Formated Phone
  const formattedPhoneNumber = (phone) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phone, 'TN')
    return parsedPhoneNumber ? parsedPhoneNumber?.formatNational() : phone
  }
  // Init Caracters Fields
  const onlyCaractersKey = ["first_name", "last_name"];
  const onlyCharachters = value => {
    const result = value.replace(/[^a-zA-z :space]/g, '');
    return result
  };

  // Callback Input Change
  const handleChangeInput = (e) => {
    const { name, value } = e.target
    if (name == "phone_number") {
      if (value.toString() != "" && (isNaN(value.replaceAll(" ", "")) || value.toString().indexOf(",") != -1 || value.toString().indexOf(".") != -1 || value.replaceAll(" ", "").length > 8)) {
        return
      }
    }
    if (!validation[name]) {
      value?.toString().trim().length && setValidation({
        ...validation,
        [name]: true
      })
      setTimeout(() => {
        const element = document.getElementById(name).classList
        element.add("label-top")
        name === "phone_number" && element.add('input-focused')
      },);
    }

    return setFormFields({
      ...formFields,
      [name]: onlyCaractersKey.find(el => el === name) ? onlyCharachters(value) : value
    })
  }

  // Before input phone
  const selectPhoneCode = (
    <div className='d-flex align-items-center'>
      <div className='mrc-14' >
        <PhoneApplication />
      </div>
      <div className='select-phone d-flex flex-column'>
        <div className='phone-text mb-1'>
          Télèphone
        </div>
        <Select defaultValue="TN" className='phone-ant-select' suffixIcon={<SelectArrow />}>
          <Option value={"TN"}>
            <div className='d-flex align-items-center flag-option'>
              <TN className='mr-1' /> <span className='text'>+216</span>
            </div>
          </Option>
        </Select>
      </div>
    </div>
  )
  const handleFocusInput = (id) => {
    const element = document.getElementById(id)
    !element.classList.contains('label-top') && element.classList.add("label-top")
    id === "phone_number" && element.classList.add("input-focused")
  }
  const handleBlurInput = (e, id) => {
    const value = formFields[id]
    const element = document.getElementById(id)
    value.toString().trim().length === 0 && element.classList.remove('label-top')
    id === "phone_number" && element.classList.remove("input-focused")
  }

  // Validate Form
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const phoneValidation = (phone) => phone.trim().length > 0 && parsePhoneNumberFromString(phone, "TN").isValid()
  const emailValidation = (email) => email.trim().length > 0 && emailRegex.test(email.trim())
  const validationSchema = () => {
    let newvalidtionSchema = {}
    Object.keys(FormSchema).forEach(el => newvalidtionSchema = {
      ...newvalidtionSchema,
      [el]: el === "email" ? emailValidation(formFields[el]) : el === "phone_number" ? phoneValidation(formFields[el]) : formFields[el].toString().length > 0
    })
    setValidation(newvalidtionSchema)
    if (!newvalidtionSchema.email && formFields.email.trim().length) {
      setTimeout(() => {
        document.getElementById("email").classList.add('label-top')
      },);

    }
    if (!newvalidtionSchema.phone_number && formFields.phone_number.trim().length) {
      setTimeout(() => {
        document.getElementById("phone_number").classList.add('label-top')
      },);
    }

    return Object.values(newvalidtionSchema).every(el => el)
  }

  // Handle Submit Click
  const onSubmit = async () => {
    setloader(true);
    if (!validationSchema()) {
      message.error("Veuillez vérifier vos données saisies")
      setloader(false);
      return
    }
    //Send Data to Api
    try {
      await publicServices.addContact(formFields);
      message.success('Votre message a été envoyé avec succès');
      setFormFields(FormSchema);
      Object.keys(FormSchema).forEach(item => document.getElementById(item).classList.remove('label-top'));
      setloader(false);
    } catch (e) {
      setloader(false);
    }

  }

  return (
    <>
      <HeaderFront />
      <div className="container contact-page application-form-container">
        <h1 className="title-center">Contactez-nous</h1>
        <p className="subtitle">Si vous avez des questions, n'hésitez pas à nous contactez.</p>
        <div className='custom-form form-contact'>
          <Row>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.last_name })} id='last_name'>
                <Input value={formFields.last_name} name="last_name" onChange={handleChangeInput} prefix={<UserApplication />} className='custom-input' onFocus={() => handleFocusInput("last_name")} onBlur={(e) => handleBlurInput(e, "last_name")} />
                <div className='floating-label'>
                  Nom
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.last_name && "Champ requis"
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.first_name })} id='first_name'>
                <Input value={formFields.first_name} name="first_name" onChange={handleChangeInput} prefix={<UserApplication />} className='custom-input' onFocus={() => handleFocusInput("first_name")} onBlur={(e) => handleBlurInput(e, "first_name")} />
                <div className='floating-label'>
                  Prénom
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.first_name && "Champ requis"
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact custom-input-wrapper', { "error-input": !validation.email })} id='email'>
                <Input value={formFields.email} name="email" onChange={handleChangeInput} prefix={<EmailApplication />} className='custom-input' onFocus={() => handleFocusInput("email")} onBlur={(e) => handleBlurInput(e, "email")} />
                <div className='floating-label'>
                  Adresse email
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.email ? formFields.email.trim().length ? "Veuillez saisir une adresse mail valide" : "Champ requis" : ""
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact input-phone ', { "error-input": !validation.phone_number })} id='phone_number'>
                <Input name="phone_number" value={formattedPhoneNumber(formFields.phone_number)} addonBefore={selectPhoneCode} onChange={handleChangeInput} onFocus={() => handleFocusInput("phone_number")} onBlur={(e) => handleBlurInput(e, "phone_number")} />
                <div className='floating-label'>
                  Téléphone
                </div>

              </div>
              <div className='error-message'>
                {
                  !validation.phone_number ? formFields.phone_number.length ? "Veuillez saisir un numéro valide" : "Champ requis" : ""
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={`custom-input-wrapper ${!validation.category ? "error-input" : ""}`} id="category">
                <Select value={formFields.category} onChange={(e) => {
                  if (!validation.category) {
                    setValidation({
                      ...validation,
                      category: true
                    })
                    setTimeout(() => {
                      document.getElementById("category").classList.add('label-top');
                    });
                  }
                  setFormFields({
                    ...formFields,
                    category: e
                  })
                }} onFocus={() => handleFocusInput('category')} onBlur={(e) => handleBlurInput(e, "category")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
                  <Option value={"Information"}> Information </Option>
                  <Option value={"Réclamation"}> Réclamation </Option>
                  <Option value={"Question"}> Question </Option>
                </Select>
                <div className='prefix-select-icon'>
                  <Civility />
                </div>
                <div className='floating-label'>
                  Catégorie
                </div>
              </div>
              <div className='error-message'>
                {
                  !validation.category && "Champ requis"
                }
              </div>
            </Col>
            <Col className='mbc-20' xs={24}>
              <div className={clsx('input-contact input-phone textarea-field custom-textarea-wrapper', { "error-input": !validation.message })} id='message'>
                <TextArea rows={4} name="message" value={formFields.message} onChange={handleChangeInput} className='custom-input' onFocus={() => handleFocusInput("message")} onBlur={(e) => handleBlurInput(e, "message")} />
                <div className='floating-label-message'>
                  Message
                </div>

              </div>
              <div className='error-message'>
                {
                  !validation.message ? "Champ requis" : ""
                }
              </div>
            </Col>
            <Col xs={24}>
              <Button type='primary' loading={loader} onClick={onSubmit} className='custom-button green-button w-100 custom-font d-flex align-items-center justify-content-center'>
                Envoyer
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <FooterFront />
    </>
  );

}

export default Contact