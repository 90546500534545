import { GET_GOVS, GET_GOVS_FRONT, GET_GOVS_SUCCESS,GET_FIELDS_FRONT,GET_FIELDS_SUCCESS,GET_CATEGORY_CONTACT,GET_CATEGORY_CONTACT_SUCCESS} from "redux/constants/Governoments";

export const getGovs = () => {
    return {
        type: GET_GOVS
    };
};

export const getGovsFront = () => {
    return {
        type: GET_GOVS_FRONT
    };
};

export const getGovsSuccess = (govs) => {
    return {
        type: GET_GOVS_SUCCESS,
        payload: govs
    }
};

export const getFieldsApp = () => {
    return {
        type: GET_FIELDS_FRONT
    };
};

export const getFieldsSuccess = (fields) => {
    return {
        type: GET_FIELDS_SUCCESS,
        payload: fields
    }
};

//Contact Category

export const getCategoryContact = () => {
    return {
        type: GET_CATEGORY_CONTACT,
    }
}
export const getCategoryContactSuccess = (category_contact) => {
    return {
        type: GET_CATEGORY_CONTACT_SUCCESS,
        payload: category_contact
    }
}