import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_APPLICATION_STATUS
} from '../constants/ApplicationStatus';
import {
    getApplicationStatusSuccess
} from "../actions/ApplicationStatus";


import services from 'services/PrivateServices';




export function* GetApplicationStatus() {

    yield takeEvery(GET_APPLICATION_STATUS, function* () {

        try {

            const data = yield call(services.getApplicationStatusData);

            const fomattedData = data.data

            yield put(getApplicationStatusSuccess(fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetApplicationStatus),

    ]);
}
