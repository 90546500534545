
import {
    GET_RECLAMATIONS,
    GET_RECLAMATIONS_SUCCESS, 
    UPDATE_RECLAMATIONS, 
    UPDATE_RECLAMATIONS_SUCCESS
} from '../constants/Reclamations';

const initState = {
    loading: false,
    reclamations: null,
    count: null,
}

const reclamations = (state = initState, action) => {
    switch (action.type) {
        case GET_RECLAMATIONS:
            return {
                ...state,
                loading: true
            }
        case GET_RECLAMATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                reclamations: action.payload.data,
                count: action.payload?.meta?.filter_count
            }
        case UPDATE_RECLAMATIONS:
            return {
                ...state,
                loading: true
            }
        case UPDATE_RECLAMATIONS_SUCCESS:
            return state
        default:
            return state;
    }
}

export default reclamations