
import { GET_COMPANY, GET_COMPANY_SUCCESS } from "redux/constants/CompanyType";

const initState = {
    loading: false,
    company: null
}

const company = (state = initState, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                loading: true
            }
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload
            }
        default:
            return state;
    }
}

export default company