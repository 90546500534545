import { GET_COMPANY, GET_COMPANY_SUCCESS } from "redux/constants/CompanyType";

export const getCompany = () => {
    return {
        type: GET_COMPANY
    };
};

export const getCompanySuccess = (company) => {
    return {
        type: GET_COMPANY_SUCCESS,
        payload: company
    }
};
