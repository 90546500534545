export const GET_APPLICATIONS = 'applications/GET_APPLICATIONS'
export const GET_APPLICATIONS_SUCCESS = 'applications/GET_APPLICATIONS_SUCCESS'
export const GET_LISTED_APPLICATIONS = 'applications/GET_LISTED_APPLICATIONS'
export const GET_LISTED_APPLICATIONS_SUCCESS = 'applications/GET_LISTED_APPLICATIONS_SUCCESS'
export const GET_CALENDAR_APPLICATIONS = 'applications/GET_CALENDAR_APPLICATIONS'
export const GET_CALENDAR_APPLICATIONS_SUCCESS = 'applications/GET_CALENDAR_APPLICATIONS_SUCCESS'
export const GET_APPLICATIONS_FAILED = 'applications/GET_APPLICATIONS_FAILED'
export const GET_APPLICATIONS_BY_ID = 'applications/GET_APPLICATIONS_BY_ID'
export const GET_APPLICATIONS_BY_ID_SUCCESS = 'applications/GET_APPLICATIONS_BY_ID_SUCCESS'
export const GET_APPLICATIONS_FIELDS = 'applications/GET_APPLICATIONS_FIELDS'
export const GET_APPLICATIONS_FIELDS_SUCESS = 'applications/GET_APPLICATIONS_FIELDS_SUCCESS'
export const GET_APPLICATION_STEPS = 'applications/GET_APPLICATION_STEPS'
export const GET_APPLICATION_STEPS_SUCESS = 'applications/GET_APPLICATION_STEPS_SUCCESS'