import { GET_APPLICATIONS_DASHBOARD, GET_APPLICATIONS_SUCCESS_DASHBOARD} from "redux/constants/Dashboard"

export const getApplicationsDashboard = () => {
    return {
        type: GET_APPLICATIONS_DASHBOARD,
    }
}
export const getApplicationsDashboardSuccess = (applications,users) => {
    return {
        type: GET_APPLICATIONS_SUCCESS_DASHBOARD,
        applications,
        users
    }
}
