
import { GET_POS, GET_POS_SUCCESS, RESET_POS } from "redux/constants/POS";

const initState = {
    loading: false,
    pos: null
}

const pos = (state = initState, action) => {
    switch (action.type) {
        case GET_POS:
            return {
                ...state,
                loading: true
            }
        case GET_POS_SUCCESS:
            return {
                ...state,
                loading: false,
                pos: action.payload
            }
        case RESET_POS:
            return {
                ...state,
                pos: null
            }
        default:
            return state;
    }
}

export default pos