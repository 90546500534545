import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { message, notification } from 'antd';
import Utils from 'utils';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})
service.defaults.baseURL = process.env.REACT_APP_BASE_URL
// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	
  if (jwtToken) {
    config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
  }

  if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
		history.push(ENTRY_ROUTE)
		window.location.reload();
  }

  return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
  Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}
	
	// Remove token and redirect 
	if (error.response.status === 401) {
		notificationParam.message = Utils.setLocale("session_expired")
		localStorage.removeItem(AUTH_TOKEN)
		history.push(ENTRY_ROUTE)
		window.location.reload();
	}

	if (error.response.status !== 401)  {
		notificationParam.message = "Une erreur est survenue"
	}
	

	message.error(notificationParam.message)

	return Promise.reject(error);
});

export default service