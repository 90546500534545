import React, { useState, useEffect } from 'react'
import { Col, Steps, Row, Input, Select, Button, DatePicker, ConfigProvider, message, Radio } from 'antd';
import { FileSyncOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Logo from "assets/img/landing/logo.svg"
import clsx from 'clsx';
import { ReactComponent as Civility } from 'assets/img/landing/civility.svg';
import { ReactComponent as SelectArrow } from 'assets/img/landing/select-arrow.svg';
import { ReactComponent as UserApplication } from 'assets/img/landing/user-application.svg';
import { ReactComponent as EmailApplication } from 'assets/img/landing/email-application.svg';
import { ReactComponent as HomeApplication } from 'assets/img/landing/home.svg';
import { ReactComponent as GouvernoratApplication } from 'assets/img/landing/gouvernorat.svg';
import { ReactComponent as DegreeApplication } from 'assets/img/landing/degree.svg';
import { ReactComponent as CalendarApplication } from 'assets/img/landing/calendar.svg';
import { ReactComponent as CakeApplication } from 'assets/img/landing/cake.svg';
import { ReactComponent as PhoneApplication } from 'assets/img/landing/phone.svg';
import { ReactComponent as TN } from 'assets/img/landing/TN.svg';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Link, useHistory } from "react-router-dom";
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import Loading from 'components/shared-components/Loading';
import axios from 'axios';
import Utils from 'utils';
import Confettis from 'assets/img/landing/confettis.png'
import { ReactComponent as House } from 'assets/img/landing/house.svg';
import moment from 'moment';
import { getGovsFront, getFieldsApp } from 'redux/actions/Governoments';
import publicServices from 'services/PublicService';
import Closed from './Closed';
const hiddenDynmaicFields = ["status", "isFranchise"]
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const formattedPhoneNumber = (phone) => {

  const parsedPhoneNumber = parsePhoneNumberFromString(phone, 'TN')

  return parsedPhoneNumber ? parsedPhoneNumber?.formatNational() : phone

}
const onlyCaractersKey = ["first_name", "last_name", "city"]

const handleHideOption = (canditatureType, question) => {

  return ((canditatureType == "GL") && (question.field === "managment_type")) ? question.meta.options?.choices.filter(el => el.value != "me_and_other") : question.meta.options?.choices
}



const defaultApplicationSchema = {
  type: "",
  gender: "",
  first_name: "",
  last_name: "",
  birth_date: "",
  email: "",
  phone_number: "",
  address: "",
  governorate_id: "",
  city: "",
  relation: "",
  children_number: ""
}
const defaultApplicationSchemaValidation = {
  type: true,
  gender: true,
  first_name: true,
  last_name: true,
  birth_date: true,
  email: true,
  phone_number: true,
  address: true,
  governorate_id: true,
  city: true,
  relation: true,
  children_number: true
}

const GLFiledsSchemaName = "free_managment_field"
const FRFiledsSchemaName = "franchise_field"
const baseUrl = process.env.REACT_APP_BASE_URL
const { TextArea } = Input
const { Step } = Steps;
const { Option } = Select
const isShownField = (parentState, rules) => {
  if (!parentState.length) return false
  if (rules.operator === "_eq") return parentState === rules.value
  if (rules.operator === "_neq") return parentState != rules.value
}
const DynamicComponent = (props) => {
  const { canditatureType, question, state, onFocus, onBlur, handleChange, validationSchema, validationList, questionNumber, onlyCharachters, handleChangeValidation } = props
  if (question.meta.interface === "select-dropdown") return (
    <>
      <div className={clsx('custom-input-wrapper label-top', { "error-input": validationList.includes(question.field) && !validationSchema[question.field] }, { "active-input": state[question.field].length > 0 })} id={question.field}>
        <Select showSearch value={state[question.field]} onChange={(e) => {
          !validationSchema[question.field] && handleChangeValidation({
            ...validationSchema,
            [question.field]: true
          })
          handleChange({
            ...state,
            [question.field]: e
          })

        }} onFocus={() => onFocus(question.field)} onBlur={(e) => onBlur(e, question.field)} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
          {
            question.meta.options.choices.map(el => <Option value={el.value} key={el.value}> {el.text} </Option>)
          }
        </Select>
        <div className='prefix-select-icon'>
          <DegreeApplication />
        </div>
        <div className='floating-label'>
          {
            question.meta.note
          }
        </div>
      </div>
      <div className='error-message'>
        {
          validationList.includes(question.field) && !validationSchema[question.field] && "Champ requis"
        }
      </div>
    </>
  )

  if (question.meta.interface === "input") return (
    <>
      <div className={clsx('custom-input-wrapper', { "error-input": validationList.includes(question.field) && !validationSchema[question.field] }, { "active-input": state[question.field].length > 0 })} id={question.field}>
        <Input value={state[question.field]} name={question.field} onChange={(e) => {
          !validationSchema[question.field] && handleChangeValidation({
            ...validationSchema,
            [question.field]: true
          })
          handleChange({
            ...state,
            [question.field]: e.target.value
          })
        }} prefix={<UserApplication />} onFocus={() => onFocus(question.field)} className='custom-input' onBlur={(e) => onBlur(e, question.field)} />
        <div className='floating-label'>
          {question.meta.note}
        </div>

      </div>
      <div className='error-message'>
        {
          validationList.includes(question.field) && !validationSchema[question.field] && "Champ requis"
        }
      </div>
    </>


  )
  if (question.meta.interface === "select-radio") return (
    <>
      <div className={clsx('custom-radio-wrapper mb-4', { "error-input": validationList.includes(question.field) && !validationSchema[question.field] }, { "active-input": state[question.field].length > 0 })} id={question.field}>
        <div className='radio-description mb-2'>
          <span className='number-step-form'>{questionNumber}.</span> {question.meta.note}
        </div>
        <div className='radios pad-left-input'>
          <Radio.Group onChange={(e) => {
            !validationSchema[question.field] && handleChangeValidation({
              ...validationSchema,
              [question.field]: true
            })
            handleChange({
              ...state,
              [question.field]: e.target.value
            })
          }} value={state[question.field]} >
            {
              handleHideOption(canditatureType, question).map(option => <Radio size='large' value={option.value} key={option.value} >
                <span className='label-name' dangerouslySetInnerHTML={{ __html: option.text }}></span>
              </Radio>)
            }
          </Radio.Group>
          <div className='error-message'>
            {
              validationList.includes(question.field) && !validationSchema[question.field] && "Champ requis"
            }
          </div>
        </div>
        {
          question.relatedField && isShownField(state[question.field], question.relatedField.ruleToShow) && <>
            <div className={clsx('custom-input-wrapper pad-left-input', { "error-input": validationList.includes(question.relatedField.field) && !validationSchema[question.relatedField.field] })} id={question.relatedField.field}>
              <div className='relative'>
                <Input value={state[question.relatedField.field]} name={question.relatedField.field} onChange={(e) => {
                  !validationSchema[question.relatedField.field] && onlyCharachters(e.target.value)?.trim().length > 0 && handleChangeValidation({
                    ...validationSchema,
                    [question.relatedField.field]: true
                  })
                  setTimeout(() => {
                    document.getElementById(question.relatedField.field).classList.add('label-top')
                  },);
                  handleChange({
                    ...state,
                    [question.relatedField.field]: onlyCharachters(e.target.value)
                  })
                }

                } prefix={<UserApplication />} onFocus={() => onFocus(question.relatedField.field)} className='custom-input' onBlur={(e) => onBlur(e, question.relatedField.field)} />
                <div className='floating-label'>
                  {question.relatedField.meta.note}
                </div>
              </div>
              <div className='error-message'>
                {
                  validationList.includes(question.relatedField.field) && !validationSchema[question.relatedField.field] && "Champ requis"
                }
              </div>
            </div>
          </>
        }
      </div>

    </>
  )
  if (question.meta.interface === "input-multiline") return (
    <>
      <div className={clsx('input-contact input-phone textarea-field custom-textarea-wrapper',)} id={question.field}>
        <TextArea rows={4} name={question.field} onChange={(e) => {
          handleChange({
            ...state,
            [question.field]: e.target.value
          })
        }} className='custom-input' onFocus={() => onFocus(question.field)} onBlur={(e) => onBlur(e, question.field)} />
        <div className='floating-label-message'>
          {
            question.meta.note
          }
        </div>

      </div>
    </>
  )
  return <></>
}




const Application = () => {
  const history = useHistory()
  const { type } = useSelector(state => state.landing)
  const [currentStep, setCurrentStep] = useState(0)
  const [application, setApplication] = useState(defaultApplicationSchema)
  const [validation, setValidation] = useState(defaultApplicationSchemaValidation)
  const [isPending, setisPending] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dynamicQuestions, setDynamicQuestions] = useState({})
  const [dynamicQuestionsValidation, setDynamicQuestionsValidation] = useState({})
  const [dynamicQuestionsValidationList, setDynamicQuestionsValidationList] = useState([])
  const [questionsToShow, setQuestionsToShow] = useState([])
  const [errorField, setErrorField] = useState(false)
  const [errorUsed, setErrorUsed] = useState(false)
  const { govs } = useSelector(state => state.govs)
  const { fields_app: questions } = useSelector(state => state.govs)
  const dispatch = useDispatch()

  useEffect(() => {
    setApplication({
      ...application, type: type
    })

  }, [type])

  const scrollToTop = () => window.scrollTo({
    top: 0,
    behavior: "smooth"
  })

  /*
  * Get Governerats & fields application
  */
  useEffect(() => {
    scrollToTop()
    !govs && dispatch(getGovsFront())
    !questions && dispatch(getFieldsApp())
  }, [])

  const filtredQuestions = (currenttype) => {
    const isInForm = "isApplicationForm"
    const isFranchise = currenttype === "FR" ? GLFiledsSchemaName : FRFiledsSchemaName

    const newQuestions = questions?.filter(question => !question.meta.hidden && !hiddenDynmaicFields.find(el => el === question.field) && question.meta.conditions?.find(el => el.name === isInForm)).filter(el => !el.meta.conditions.map(el => el.name).includes(isFranchise)).sort(Utils.formFieldsCallback)
    setQuestionsToShow(newQuestions)
  }
  useEffect(() => {
    if (!application.type.length) {
      return
    }
    filtredQuestions(application.type)
  }, [application.type, questions])

  const handleFocusInput = (id) => {
    const element = document.getElementById(id)
    !element.classList.contains('label-top') && element.classList.add("label-top")
    id === "phone_number" && element.classList.add("input-focused")
  }
  const handleBlurInput = (e, id) => {
    const { target } = e
    const value = application[id] || dynamicQuestions[id] || " "

    const element = document.getElementById(id)
    value.toString().trim().length === 0 && element.classList.remove('label-top')
    id === "phone_number" && element.classList.remove("input-focused")
  }
  const onlyCharachters = value => {
    const result = value.replace(/[^a-zA-z :space]/g, '');
    return result
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target


    if (name == "phone_number") {
      if (value.toString() != "" && (isNaN(value.replaceAll(" ", "")) || value.toString().indexOf(",") != -1 || value.toString().indexOf(".") != -1 || value.replaceAll(" ", "").length > 8)) {
        return
      }
    }
    if (name === "children_number") {
      if (value.toString() != "" && (isNaN(value.replaceAll(" ", "")) || value.toString().indexOf(",") != -1 || value.toString().indexOf(".") != -1) || Number(value) > 10) {
        return
      }
    }
    if (!validation[name]) {
      value?.toString().trim().length && setValidation({
        ...validation,
        [name]: true
      })
      setTimeout(() => {
        const element = document.getElementById(name).classList
        element.add("label-top")
        name === "phone_number" && element.add('input-focused')

      },);

    }
    return setApplication({
      ...application,
      [name]: onlyCaractersKey.find(el => el === name) ? onlyCharachters(value) : value
    })
  }
  const selectPhoneCode = (
    <div className='d-flex align-items-center'>
      <div className='mrc-14' >
        <PhoneApplication />
      </div>
      <div className='select-phone d-flex flex-column'>
        <div className='phone-text mb-1'>
          Téléphone
        </div>
        <Select defaultValue="TN" className='phone-ant-select' suffixIcon={<SelectArrow />}>
          <Option value={"TN"}>
            <div className='d-flex align-items-center flag-option'>
              <TN className='mr-1' /> <span className='text'>+216</span>
            </div>


          </Option>
        </Select>
      </div>

    </div>

  )
  const phoneValidation = (phone) => phone.trim().length > 0 && parsePhoneNumberFromString(phone, "TN").isValid()
  const emailValidation = (email) => email.trim().length > 0 && emailRegex.test(email.trim())

  const validationSchema = () => {
    let newvalidtionSchema = {}
    Object.keys(defaultApplicationSchema).forEach(el => newvalidtionSchema = {
      ...newvalidtionSchema,
      [el]: el === "email" ? emailValidation(application[el]) : el === "phone_number" ? phoneValidation(application[el]) : application[el].toString().length > 0
    })
    if (application['relation'] && application['relation'] === "single") newvalidtionSchema = { ...newvalidtionSchema, children_number: true }
    setValidation(newvalidtionSchema)
    if (!newvalidtionSchema.email && application.email.trim().length) {
      setTimeout(() => {
        document.getElementById("email").classList.add('label-top')
      },);

    }
    if (!newvalidtionSchema.phone_number && application.phone_number.trim().length) {
      setTimeout(() => {
        document.getElementById("phone_number").classList.add('label-top')
      },);
    }
    return Object.values(newvalidtionSchema).every(el => el)
  }

  const validateDynamicSchema = () => {
    let dynmaicSchema = []
    questionsToShow.forEach(question => {
      if (question.relatedField) {
        const parentSchema = question.field
        const schema = question.relatedField.field;
        const rule = question.relatedField.ruleToShow;
        const interfaceField = question.relatedField.meta.interface
        dynmaicSchema.push({
          parentSchema,
          schema,
          rule,
          interfaceField
        })
      }
    })
    let newValidation = {}
    dynamicQuestionsValidationList.forEach(schema => {
      const isRelatedField = dynmaicSchema.find(el => el.schema === schema);

      newValidation[schema] = isRelatedField ? (!isShownField(dynamicQuestions[isRelatedField.parentSchema], isRelatedField.rule) ||
        (isShownField(dynamicQuestions[isRelatedField.parentSchema], isRelatedField.rule) && dynamicQuestions[schema].length > 0))
        : dynamicQuestions[schema].length > 0
    })

    setDynamicQuestionsValidation(newValidation)
    return Object.values(newValidation).every(el => el)
  }
  const handTypeChange = (currentType) => {
    !validation.type && setValidation({
      ...validation,
      type: true
    })
    setApplication({
      ...application,
      type: currentType
    })
  }
  const onSubmit = async () => {
    if (currentStep === 0) {

      if (!validationSchema()) {
        setErrorField(true)
        return
      }
      setisPending(true)
      let data = await publicServices.getApplications(application.phone_number.replace(/\s/g, ''),application.email)
      setisPending(false)
      let proceed = data?.data?.proceed

      if (proceed) {
        setErrorUsed(false)
      } else {
        setErrorField(false)
        setErrorUsed(true)
        return
      }

      if (proceed) {

        const currentType = application.type === "FR" ? FRFiledsSchemaName : GLFiledsSchemaName
        let questionSchema = {}
        let questionSchemaValidation = {}
        let questionsToShowCopy = [...questionsToShow]
        questionsToShow.forEach(question => {

          questionSchema[question.field] = question.schema.default_value || ""
          if ((question.meta.required || question.meta.conditions.find(el => el.name === "show_field")?.required || question.meta.conditions.find(el => el.name === currentType)?.required)) {
            {
              questionSchemaValidation[question.field] = true
            }
          }
          const conditionalField = question.meta.conditions.find(el => el.name === "show_field")
          if (conditionalField) {
            const rule = conditionalField.rule?._and[0]
            const dependentSchema = Object.keys(rule)[0]
            const [operator, value] = Object.entries(Object.values(rule)[0]).flatMap(el => el)
            questionsToShowCopy = questionsToShowCopy.map(el => {
              if (el.field === dependentSchema) {
                return {
                  ...el,
                  relatedField: {
                    ...question,
                    ruleToShow: {
                      operator,
                      value
                    }
                  }
                }
              }
              return el
            })
          }
        })
        setDynamicQuestions(questionSchema)
        setDynamicQuestionsValidation(questionSchemaValidation)
        setDynamicQuestionsValidationList(Object.keys(questionSchemaValidation))
        setQuestionsToShow(questionsToShowCopy.filter(question => !question.meta.conditions?.find(el => el.name === "show_field")))
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setCurrentStep(1)
        return
      }
    }

    if (!validateDynamicSchema()) return message.error('Veuillez saisir les données manquantes')
    const allSchema = {
      ...application,
      email: application?.email?.trim(),
      isFranchise: application.type === "FR",
      phone_number : application?.phone_number?.replace(/\s/g, ''),
      ...dynamicQuestions
    }
    delete allSchema.type
    try {
      setisPending(true)
      await axios.post(`${baseUrl}items/application`, allSchema)
      setisPending(false)
      setCurrentStep(2)
    } catch (error) {
      message.error("Une erreur est survenue")
    }

  }


  return (
    <>
          {/* <Closed/> */}
      {errorUsed && <div className='error-strip'><FileSyncOutlined style={{ marginRight: "7px" }} /> Vous avez déjà une condidature en cours</div>}
      {errorField && <div className='error-strip'><FileSyncOutlined style={{ marginRight: "7px" }} /> Veuillez vérifier vos données saisies</div>}

      {questions && govs ? <>
        <div className='application-header d-flex justify-content-center align-items-center'>
          <Link to="/" title='Mliha Machrou3i'><img src={Logo} alt="logo" /></Link>
        </div>
  
        <div className={clsx('container mbc-50', { "felecitation-container": currentStep === 2 })}>
          {currentStep < 2 && <>

            <h1 className='text-center mt-5'>
              SOUMETTRE VOTRE CANDIDATURE
            </h1>

          </>
          }
          {currentStep < 2 && <div className='steps-container ' >
            <Steps current={currentStep} className="custom-steps" labelPlacement='vertical'>
              <Step className='custom-step-cursor' title="Informations" description="Personnelles" status={currentStep === 0 ? "process" : "finish"} />
              <Step title="Informations" description="Professionnelles" status={currentStep === 1 ? "process" : currentStep === 0 ? "wait" : "finish"} />

            </Steps>
          </div>}
          <div className={clsx('application-form-container', { 'mb-0 mt-0 h-100': currentStep === 2 })}>

            <div className={clsx('custom-form', { 'mb-0 mt-0 h-100': currentStep === 2 })}>
              <Row justify='center' className={clsx({ "h-100": currentStep === 2 })} gutter={[24]} >
                <Col xs={24} md={20} lg={14} xl={10} className={clsx({ "h-100": currentStep === 2 })}>
                  {currentStep === 0 && <Row gutter={[24]} className="mbc-40">
                    <Col md={12} className={clsx('mobile-width-50', { "error-type": !validation.type })}>
                      <div className={clsx('custom-radio mrc pointer ', { "checked": application.type == "GL" }, { "error-input": !validation.type })} onClick={() => handTypeChange("GL")}>
                        <div className='d-flex align-items-center '>
                          <span className='radio'> </span> <span className='text'>Gérance</span>
                        </div>
                      </div>

                    </Col>
                    <Col md={12} className={clsx('mobile-width-50', { "error-type": !validation.type })}>
                      <div className={clsx('custom-radio pointer ', { "checked": application.type == "FR" }, { "error-input": !validation.type })} onClick={() => handTypeChange("FR")}>
                        <div className='d-flex align-items-center '>
                          <span className='radio'> </span>  <span className='text'>Franchise</span>
                        </div>
                      </div>

                    </Col>
                    <Col xs={24} >
                      {!validation.type && <div className='error-message text-center mt-1'>
                        Veuillez choisir le type de canditature
                      </div>}
                    </Col>
                  </Row>}
                  <Row className={clsx({ "justify-content-center": isLoading }, { "h-100": currentStep === 2 })}>
                    {currentStep === 0 && <>
                      <Col className='mbc-20' xs={24}>
                        <div className={`custom-input-wrapper ${!validation.gender ? "error-input" : ""}`} id="gender">
                          <Select value={application.gender} onChange={(e) => {
                            if (!validation.gender) {
                              setValidation({
                                ...validation,
                                gender: true
                              })
                              setTimeout(() => {
                                document.getElementById('gender').classList.add('label-top')
                              },);
                            }
                            setApplication({
                              ...application,
                              gender: e
                            })
                          }} onFocus={() => handleFocusInput('gender')} onBlur={(e) => handleBlurInput(e, "gender")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
                            <Option value={"mr"}> Monsieur </Option>
                            <Option value={"ms"}> Madame </Option>
                            <Option value={"miss"}> Mademoiselle </Option>
                          </Select>
                          <div className='prefix-select-icon'>
                            <Civility />
                          </div>
                          <div className='floating-label'>
                            Civilité
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.gender && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.last_name })} id='last_name'>
                          <Input value={application.last_name} name="last_name" onChange={handleChangeInput} prefix={<UserApplication />} onFocus={() => handleFocusInput("last_name")} className='custom-input' onBlur={(e) => handleBlurInput(e, "last_name")} />
                          <div className='floating-label'>
                            Nom
                          </div>

                        </div>
                        <div className='error-message'>
                          {
                            !validation.last_name && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.first_name })} id='first_name'>
                          <Input value={application.first_name} name="first_name" onChange={handleChangeInput} prefix={<UserApplication />} onFocus={() => handleFocusInput("first_name")} className='custom-input' onBlur={(e) => handleBlurInput(e, "first_name")} />
                          <div className='floating-label'>
                            Prénom
                          </div>

                        </div>
                        <div className='error-message'>
                          {
                            !validation.first_name && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.birth_date })} id='birth_date'>
                          <ConfigProvider locale={locale}>
                            <DatePicker defaultPickerValue={moment().subtract(18, "y")} disabledDate={d => d > moment().subtract(18, "y")}
                              inputReadOnly format={"DD/MM/YYYY"} allowClear={false} name='birth-date' value={application.birth_date} onChange={(e) => {

                                if (!validation.birth_date) {
                                  setValidation({
                                    ...validation,
                                    birth_date: true
                                  })
                                  setTimeout(() => {
                                    document.getElementById("birth_date").classList.add("label-top")
                                  });
                                }
                                setApplication({
                                  ...application,
                                  birth_date: e
                                })
                              }
                              } locale={locale} placeholder='' suffixIcon={<CalendarApplication />} onFocus={() => handleFocusInput("birth_date")} onBlur={(e) => handleBlurInput(e, "birth_date")} className='custom-datePicker w-100' />

                          </ConfigProvider>
                          <div className='floating-label'>
                            Date de naissance
                          </div>
                          <div className='prefix-select-icon'>
                            <CakeApplication />
                          </div>

                        </div>
                        <div className='error-message'>
                          {
                            !validation.birth_date && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={`custom-input-wrapper ${!validation.relation ? "error-input" : ""}`} id="relation">
                          <Select value={application.relation} onChange={(e) => {
                            if (!validation.relation) {
                              setValidation({
                                ...validation,
                                relation: true
                              })
                              setTimeout(() => {
                                document.getElementById('relation').classList.add('label-top')
                              },);
                            }
                            setApplication({
                              ...application,
                              relation: e
                            })
                          }} onFocus={() => handleFocusInput('relation')} onBlur={(e) => handleBlurInput(e, "relation")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
                            <Option value={"married"}> Marié(e) </Option>
                            <Option value={"single"}> Célibataire </Option>
                            <Option value={"divorced"}>  Divorcé(e) </Option>
                          </Select>
                          <div className='prefix-select-icon'>
                            <Civility />
                          </div>
                          <div className='floating-label'>
                            État civil
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.relation && "Champ requis"
                          }
                        </div>
                      </Col>

                      {application && application?.relation != "single" && application?.relation && <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.children_number })} id='children_number'>
                          <Input value={application.children_number} name="children_number" onChange={handleChangeInput} prefix={<HomeApplication />} onFocus={() => handleFocusInput("children_number")} className='custom-input' onBlur={(e) => handleBlurInput(e, "children_number")} />
                          <div className='floating-label'>
                            Nombre d’enfants en charge
                          </div>

                        </div>
                        <div className='error-message'>
                          {
                            !validation.children_number && "Champ requis"
                          }
                        </div>
                      </Col>}
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('input-phone', { "error-input": !validation.phone_number })} id='phone_number'>
                          <Input name="phone_number" value={formattedPhoneNumber(application.phone_number)} addonBefore={selectPhoneCode} onChange={handleChangeInput} onFocus={() => handleFocusInput("phone_number")} onBlur={(e) => handleBlurInput(e, "phone_number")} />
                          <div className='floating-label'>
                            Téléphone
                          </div>

                        </div>
                        <div className='error-message'>
                          {
                            !validation.phone_number ? application.phone_number.length ? "Veuillez saisir un numéro valide" : "Champ requis" : ""
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.email })} id='email'>
                          <Input value={application.email} name="email" onChange={handleChangeInput} prefix={<EmailApplication />} onFocus={() => handleFocusInput("email")} className='custom-input' onBlur={(e) => handleBlurInput(e, "email")} />
                          <div className='floating-label'>
                            Adresse email
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.email ? application.email.trim().length ? "Veuillez saisir une adresse mail valide" : "Champ requis" : ""
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.address })} id='address'>
                          <Input value={application.address} name="address" onChange={handleChangeInput} prefix={<HomeApplication />} onFocus={() => handleFocusInput("address")} className='custom-input' onBlur={(e) => handleBlurInput(e, "address")} />
                          <div className='floating-label'>
                            Adresse
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.address && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.city })} id='city'>
                          <Input value={application.city} name="city" onChange={handleChangeInput} prefix={<GouvernoratApplication />} onFocus={() => handleFocusInput("city")} className='custom-input' onBlur={(e) => handleBlurInput(e, "city")} />
                          <div className='floating-label'>
                            Ville
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.city && "Champ requis"
                          }
                        </div>
                      </Col>
                      <Col className='mbc-20' xs={24}>
                        <div className={clsx('custom-input-wrapper', { "error-input": !validation.governorate_id })} id="governorate_id">
                          <Select showSearch
                            filterOption={(inputValue, option) => option.label.toLocaleLowerCase().includes(inputValue) || option.label.includes(inputValue)}
                            value={application.governorate_id} onChange={(e) => {
                              if (!validation.governorate_id) {
                                setValidation({
                                  ...validation,
                                  governorate_id: true
                                })
                                setTimeout(() => {
                                  document.getElementById('governorate_id').classList.add('label-top')
                                },);
                              }
                              setApplication({
                                ...application,
                                governorate_id: e
                              })
                            }} onFocus={() => handleFocusInput('governorate_id')} onBlur={(e) => handleBlurInput(e, "governorate_id")} className='w-100 custom-select' suffixIcon={<SelectArrow />}>
                            {
                              govs && govs?.map(el => <Option value={el.id} key={el.id} label={el.name} > {el.name} </Option>)
                            }

                          </Select>
                          <div className='prefix-select-icon'>
                            <GouvernoratApplication />
                          </div>
                          <div className='floating-label'>
                            Gouvernorat
                          </div>
                        </div>
                        <div className='error-message'>
                          {
                            !validation.governorate_id && "Champ requis"
                          }
                        </div>
                      </Col>


                    </>
                    }
                    {
                      currentStep === 1 && <>
                        {isLoading ? <div className='align-self-center'>
                          <Loading />
                        </div> : <>
                          {
                            questionsToShow.map((question, index) => (
                              <Col xs={24} key={index} >
                                <DynamicComponent canditatureType={application.type} question={question} state={dynamicQuestions} onFocus={handleFocusInput} onBlur={handleBlurInput} handleChange={setDynamicQuestions} validationSchema={dynamicQuestionsValidation} validationList={dynamicQuestionsValidationList} questionNumber={index} onlyCharachters={onlyCharachters} handleChangeValidation={setDynamicQuestionsValidation} />
                              </Col>

                            ))
                          }

                        </>

                        }
                      </>
                    }

                    <Col xs={24}>
                      {!isLoading && currentStep < 2 && <Button loading={isPending} type='primary' onClick={onSubmit} className='custom-button green-button w-100 custom-font'>
                        {currentStep ? "Envoyer" : "Suivant"}
                      </Button>}
                    </Col>
                    <Col xs={24} className={clsx({ "h-100": currentStep === 2 })} >
                      {currentStep === 2 && <div className='felecitation'>
                        <div className='description'>
                          <div className='image'>
                            <img src={Confettis} alt="" />
                          </div>
                          <div className='title'>
                            FÉLICITATIONS
                          </div>
                          <p className='info'>
                            Nous vous remercions pour votre intérêt de faire partie de l’équipe MLIHA. Votre candidature a été reçue, le responsable réseaux de votre zone prendra contact avec vous dans les plus brefs délais.                        </p>
                        </div>
                        <Button className='felecitation-button' icon={<House />} onClick={() => history.push('/')}>
                          Retour à la page d’accueil
                        </Button>
                      </div>}
                    </Col>
                  </Row>


                </Col>
              </Row>

            </div>

          </div>
        </div>
      </> : <div className="loader_full_page"><Loading /></div>}
    </>

  )
}

export default Application