import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_COMPANY
} from '../constants/CompanyType';
import {
    getCompanySuccess
} from "../actions/CompanyType";


import services from 'services/PrivateServices';




export function* GetCompany() {

    yield takeEvery(GET_COMPANY, function* () {

        try {

            const data = yield call(services.getCompanyData);

            const fomattedData = data.data

            yield put(getCompanySuccess(fomattedData))

        } catch (err) {



        }

    });

}


export default function* rootSaga() {
    yield all([
        fork(GetCompany),

    ]);
}
